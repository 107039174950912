import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import {
  ConfigurationAppearanceStyleColors,
  ConfigurationAppearanceStyleFont,
  ConfigurationMap
} from './configuration-types'
enum OptionEnum {
  GLOBAL = 'GLOBAL',
  PRACTICE = 'PRACTICE',
  CATEGORY = 'CATEGORY'
}
interface DataMap {
  configurationMap: ConfigurationMap
}
interface MessageConfigurationProps {
  data: DataMap
  handleConfirm(
    option: OptionEnum,
    font: ConfigurationAppearanceStyleFont,
    color: ConfigurationAppearanceStyleColors
  ): void
  option: OptionEnum
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))(MuiDialogContent)

const AppearanceConfig: React.FC<MessageConfigurationProps> = ({
  data,
  option,
  handleConfirm
}) => {
  const [appearanceDialog, setAppearanceDialog] = useState<boolean>(false)
  const [currentFont, setCurrentFont] = useState<
    ConfigurationAppearanceStyleFont
  >({
    fontAlign: '',
    fontSize: '',
    fontFamily: ''
  })
  const [currentColors, setCurrentColors] = useState<
    ConfigurationAppearanceStyleColors
  >({
    backgroundColor: '',
    primary: '',
    secondary: '',
    textColor: ''
  })

  const configStyle = useCallback(() => {
    switch (option) {
      case OptionEnum.CATEGORY:
        return data?.configurationMap.appearance?.categoryStyle
      case OptionEnum.PRACTICE:
        return data?.configurationMap.appearance?.practiceStyle
      case OptionEnum.GLOBAL:
        return data?.configurationMap.appearance?.globalStyle
      default:
        return { font: { ...currentFont }, colors: { ...currentColors } }
    }
  }, [option, currentColors, currentFont, data])

  useEffect(() => {
    setCurrentFont({
      fontAlign: configStyle()?.font?.fontAlign || '',
      fontFamily: configStyle()?.font?.fontFamily || '',
      fontSize: configStyle()?.font?.fontSize || ''
    })
    setCurrentColors({
      backgroundColor: configStyle()?.colors?.backgroundColor || '',
      textColor: configStyle()?.colors?.textColor || '',
      primary: configStyle()?.colors?.primary || '',
      secondary: configStyle()?.colors?.secondary || ''
    })
    // eslint-disable-next-line
  }, [])

  const handleChangeFont = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = event.currentTarget
    setCurrentFont({ ...currentFont, [name]: value })
  }
  const handleChangeFontAlign = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCurrentFont({ ...currentFont, fontAlign: event.target.value as string })
  }
  const handleChangeColors = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value, name } = event.currentTarget
    setCurrentColors({ ...currentColors, [name]: value })
  }

  const handleOpen = () => {
    setAppearanceDialog(true)
  }

  const handleClose = () => {
    setAppearanceDialog(false)
  }

  const confirmSave = () => {
    return handleConfirm(option, currentFont, currentColors)
  }

  const confirmDefault = () => {
    let defaultFont = {
      fontSize: '1.5rem',
      fontFamily: 'Roboto',
      fontAlign: 'left'
    }
    let defaultColors = {
      primary: '#bb141a',
      secondary: '#ED1C24',
      textColor: '#f9f9f9',
      backgroundColor: '#f9f9f9'
    }

    if (option === OptionEnum.CATEGORY) {
      defaultFont = {
        fontSize: '1.5rem',
        fontFamily: 'Roboto',
        fontAlign: 'center'
      }
      defaultColors = {
        primary: '#bb141a',
        secondary: '#ED1C24',
        textColor: '#2C2C2C',
        backgroundColor: '#E0E0E0'
      }
    } else if (option === OptionEnum.PRACTICE) {
      defaultFont = {
        fontSize: '1.5rem',
        fontFamily: 'Roboto',
        fontAlign: 'left'
      }
      defaultColors = {
        primary: '#bb141a',
        secondary: '#ED1C24',
        textColor: '#2C2C2C',
        backgroundColor: '#E0E0E0'
      }
    }

    return handleConfirm(option, defaultFont, defaultColors)
  }

  const handleIcon = () => {
    switch (option) {
      case OptionEnum.CATEGORY:
        return <i className="fas fa-tag" />
      case OptionEnum.PRACTICE:
        return <i className="fas fa-clipboard-list" />
      case OptionEnum.GLOBAL:
        return <i className="fas fa-palette" />
    }
  }

  const handleTitle = () => {
    switch (option) {
      case OptionEnum.CATEGORY:
        return 'Categoria'
      case OptionEnum.PRACTICE:
        return 'Práticas'
      case OptionEnum.GLOBAL:
        return 'Global'
    }
  }
  const showFontSize = () => {
    switch (option) {
      case OptionEnum.CATEGORY:
      case OptionEnum.PRACTICE:
      case OptionEnum.GLOBAL:
        return true
      default:
        return false
    }
  }

  const showFontFamily = () => {
    switch (option) {
      case OptionEnum.CATEGORY:
      case OptionEnum.PRACTICE:
      case OptionEnum.GLOBAL:
        return true
      default:
        return false
    }
  }

  const showFontAlign = () => {
    switch (option) {
      case OptionEnum.CATEGORY:
      case OptionEnum.PRACTICE:
        return true
      default:
        return false
    }
  }

  const showColorBackground = () => {
    switch (option) {
      case OptionEnum.GLOBAL:
        return true
      default:
        return false
    }
  }

  const showColorPrimary = () => {
    switch (option) {
      case OptionEnum.GLOBAL:
        return true
      default:
        return false
    }
  }

  const showColorSecondary = () => {
    switch (option) {
      case OptionEnum.GLOBAL:
        return true
      default:
        return false
    }
  }

  const showColorText = () => {
    switch (option) {
      case OptionEnum.CATEGORY:
      case OptionEnum.PRACTICE:
      case OptionEnum.GLOBAL:
        return true
      default:
        return false
    }
  }

  return (
    <>
      {/* Messages section */}
      <Grid item>
        <Typography variant="h5" color="textPrimary" component="h5">
          {handleTitle()}
        </Typography>
        <br />
        <Grid item>
          <Fab
            color="primary"
            aria-label="message"
            onClick={handleOpen}
            style={{ fontSize: '1.5rem', textAlign: 'center' }}
          >
            {handleIcon()}
          </Fab>
        </Grid>
        {/* Show dialog */}
        <Dialog
          open={appearanceDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Alterar configurações de aparência ${handleTitle()} ?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Alterando a aparência, você ira atualizar os atributos de
              estilização para todos os participantes em tempo real, algumas
              configurações podem atrapalhar a visualização do usúario.
            </DialogContentText>
            <p>Fonte</p>
            {showFontSize() && (
              <TextField
                autoFocus
                fullWidth
                label="Tamanho da fonte"
                defaultValue={configStyle()?.font?.fontSize}
                onChange={e => handleChangeFont(e)}
                name="fontSize"
                variant="outlined"
              />
            )}
            {showFontFamily() && (
              <TextField
                fullWidth
                label="Familia da fonte"
                defaultValue={configStyle()?.font?.fontFamily}
                onChange={e => handleChangeFont(e)}
                name="fontFamily"
                variant="outlined"
              />
            )}
            {showFontAlign() && (
              <InputLabel id="font-align-select">
                Alinhamento da fonte
              </InputLabel>
            )}
            {showFontAlign() && (
              <Select
                fullWidth
                id="font-align-select"
                defaultValue={configStyle()?.font?.fontAlign}
                onChange={e => handleChangeFontAlign(e)}
                name="fontAlign"
                variant="outlined"
              >
                <MenuItem value="center">Centro</MenuItem>
                <MenuItem value="left">Esquerda</MenuItem>
                <MenuItem value="right">Direita</MenuItem>
              </Select>
            )}
            <p>Cores</p>
            {showColorBackground() && (
              <TextField
                fullWidth
                label="Cor do fundo"
                defaultValue={configStyle()?.colors?.backgroundColor}
                onBlur={e => handleChangeColors(e)}
                name="backgroundColor"
                variant="outlined"
                type="color"
              />
            )}
            {showColorPrimary() && (
              <TextField
                fullWidth
                label="Cor primária"
                defaultValue={configStyle()?.colors?.primary}
                onBlur={e => handleChangeColors(e)}
                name="primary"
                variant="outlined"
                type="color"
              />
            )}
            {showColorSecondary() && (
              <TextField
                fullWidth
                label="Cor secundária"
                defaultValue={configStyle()?.colors?.secondary}
                onBlur={e => handleChangeColors(e)}
                name="secondary"
                variant="outlined"
                type="color"
              />
            )}
            {showColorText() && (
              <TextField
                fullWidth
                label="Cor do texto"
                defaultValue={configStyle()?.colors?.textColor}
                onBlur={e => handleChangeColors(e)}
                name="textColor"
                variant="outlined"
                type="color"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                confirmSave()
                handleClose()
              }}
              color="primary"
            >
              Atualizar aparência!
            </Button>
            <Button
              onClick={() => {
                confirmDefault()
                handleClose()
              }}
              color="primary"
            >
              Aparência padrão
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  )
}

export default AppearanceConfig
