import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useHistory } from 'react-router-dom'
import { pages } from './pages'
import { useKeycloak } from '@react-keycloak/web'
import { Badge, Container } from '@material-ui/core'
import { useWaitingRoom } from '../Users/provider'
import { version } from '../../../package.json'
import { PanelRoleEnum, useUser } from '../../routes/userValidation'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(5)
  },
  version: {
    position: 'absolute',
    bottom: 0,
    right: 1,
    fontSize: 10
  },
  user: {
    position: 'absolute',
    right: '24px'
  }
}))

const Layout: React.FC = ({ children }) => {
  const [open, setOpen] = React.useState(false)
  const [keycloak] = useKeycloak()

  const waitingRoom = useWaitingRoom()
  const user = useUser()

  const history = useHistory()
  const classes = useStyles()
  const theme = useTheme()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Innovare
          </Typography>
          <Typography variant="h6" align="right" className={classes.user}>
            <span style={{ marginRight: 15 }}>{user.panelMe?.name}</span>
            {user.panelMe?.roles.includes(PanelRoleEnum.ADMIN) ? (
              <i className="fas fa-user-shield" />
            ) : (
              <i className="fas fa-user-cog" />
            )}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {pages.map(page => {
            if (!page.menu) {
              return ''
            }
            return (
              <ListItem
                button
                key={page.title}
                onClick={() => history.push(page.link || '/admin')}
              >
                <ListItemIcon>
                  <i
                    className={page.icon}
                    style={{ fontSize: '1.2rem', margin: 'auto' }}
                  />
                </ListItemIcon>
                <ListItemText primary={page.title} />
              </ListItem>
            )
          })}
          <ListItem
            button
            onClick={() => history.push('/admin/waitlist/PENDING')}
          >
            <Badge
              badgeContent={
                waitingRoom?.waitingRoom
                  ? waitingRoom?.waitingRoom.PENDING
                  : null
              }
              color="primary"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <ListItemIcon>
                <i
                  className="fas fa-user-clock"
                  style={{ fontSize: '1.2rem', margin: 'auto' }}
                />
              </ListItemIcon>
            </Badge>
            <ListItemText primary="Sala de espera" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://conta.votepraticasinnovare.com.br/auth/realms/innovare/account/"
            style={{
              textDecoration: 'none',
              color: 'inherit'
            }}
          >
            <ListItem button>
              <ListItemIcon>
                <i
                  className="fas fa-user"
                  style={{ fontSize: '1.2rem', margin: 'auto' }}
                />
              </ListItemIcon>
              <ListItemText primary="Perfil" />
            </ListItem>
          </a>
          <ListItem button onClick={() => keycloak.logout()}>
            <ListItemIcon>
              <i
                className="fas fa-sign-out-alt"
                style={{ fontSize: '1.2rem', margin: 'auto' }}
              />
            </ListItemIcon>
            <ListItemText primary="Sair" />
          </ListItem>
        </List>
        <div className={classes.version}>{version}</div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar}>
          <Container maxWidth="lg" style={{ padding: '50px 0' }}>
            <div>{children}</div>
          </Container>
        </div>
      </main>
    </div>
  )
}

export default Layout
