import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  MenuItem,
  Select,
  Theme,
  Typography
} from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  ConfigurationMap,
  ConfigurationSignUpAuthTypeEnum
} from './configuration-types'

interface DataMap {
  configurationMap: ConfigurationMap
}

interface MessageConfigurationProps {
  data: DataMap | undefined
  handleConfirm(option: ConfigurationSignUpAuthTypeEnum): void
  loading: boolean
}
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))(MuiDialogContent)

const RegisterConfiguration: React.FC<MessageConfigurationProps> = ({
  data,
  handleConfirm,
  loading
}) => {
  const [messageDialog, setMessageDialog] = useState<boolean>(false)
  const [currentConfig, setCurrentConfig] = useState<
    ConfigurationSignUpAuthTypeEnum | undefined
  >(undefined)

  useEffect(() => {
    if (messageDialog) {
      setCurrentConfig(data?.configurationMap.signUp?.authType)
    }
  }, [messageDialog, data])

  const handleOpen = () => {
    setMessageDialog(true)
  }

  const handleClose = () => {
    setMessageDialog(false)
  }

  return (
    <>
      {/* Messages section */}
      <Grid item>
        <Typography variant="h5" color="textPrimary" component="h5">
          Acesso do participante
        </Typography>
        <br />
        <Grid item style={{ margin: '0 auto' }}>
          <Fab color="primary" aria-label="message" onClick={handleOpen}>
            <i className="fas fa-user-cog" style={{ fontSize: '1.5rem' }} />
          </Fab>
        </Grid>
        {/* Show dialog */}
        <Dialog
          open={messageDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form
            onSubmit={e => {
              e.preventDefault()
              if (currentConfig === undefined) {
                toast.error('Selecione uma opção valida')
              } else {
                handleConfirm(currentConfig)
                handleClose()
              }
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {'Alterar configurações de acesso do participante ?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <p>Determine a configuração de acesso do participante:</p>
                <p>
                  <strong>Público:</strong> Participantes se cadastram sozinhos
                  e não precisam aguardar aprovação.
                </p>
                <p>
                  <strong>Privado:</strong> Participantes se cadastram sozinhos
                  porém deverão aguardar na sala de espera a liberação.
                </p>
                <p>
                  <strong>Restrito:</strong> Participantes não podem se
                  cadastrar sozinhos, acesso apenas a usuários previamente
                  cadastrados pelo ADMIN.
                </p>
              </DialogContentText>
              <Select
                fullWidth
                id="font-align-select"
                name="type"
                variant="outlined"
                defaultValue={currentConfig}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setCurrentConfig(
                    e.target.value as ConfigurationSignUpAuthTypeEnum
                  )
                }
              >
                <MenuItem value={ConfigurationSignUpAuthTypeEnum.Public}>
                  Público
                </MenuItem>
                <MenuItem value={ConfigurationSignUpAuthTypeEnum.Private}>
                  Privado
                </MenuItem>
                <MenuItem value={ConfigurationSignUpAuthTypeEnum.Restricted}>
                  Restrito
                </MenuItem>
                <MenuItem value={undefined} disabled>
                  SELECIONE UMA OPÇÃO
                </MenuItem>
              </Select>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button
                color="primary"
                type="submit"
                autoFocus
                disabled={loading || currentConfig === undefined}
              >
                Atualizar acesso
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Grid>
    </>
  )
}

export default RegisterConfiguration
