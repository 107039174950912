import { gql } from '@apollo/client'

export const GET_PRACTICES = gql`
  query Practices($paramsPractices: PracticeSearchInput) {
    practices(params: $paramsPractices) {
      edges {
        _id
        name
        number
        category {
          _id
          name
          number
          quantityWin
        }
      }
    }
  }
`
export const GET_PRACTICE = gql`
  query Practice($id: ID!) {
    practice(id: $id) {
      _id
      name
      number
      category {
        _id
        name
        number
        quantityWin
      }
    }
  }
`

export const ADD_PRACTICE = gql`
  mutation CreatePractice($inputPractice: PracticeCreateInput!) {
    createPractice(input: $inputPractice) {
      message
      data {
        _id
        name
        number
      }
    }
  }
`
export const UPDATE_PRACTICE = gql`
  mutation UpdatePractice($id: ID!, $inputUpdate: PracticeUpdateInput!) {
    updatePractice(id: $id, input: $inputUpdate) {
      message
      data {
        _id
        name
        number
        category {
          _id
          name
          number
          quantityWin
        }
      }
    }
  }
`
export const DELETE_PRACTICE = gql`
  mutation DeletePractice($ids: [ID!]!) {
    deletePractice(ids: $ids) {
      message
    }
  }
`
