/* eslint-disable no-multi-str */
import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Editor } from '@tinymce/tinymce-react'
import { Button, Grid } from '@material-ui/core'
import { MessageData } from './message-types'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    btnBack: {
      padding: '16px',
      width: '40%',
      margin: '0 10% 0 0'
    },
    btnSave: {
      padding: '16px',
      width: '40%',
      margin: '0 0 0 10%'
    }
  })
)

interface FormProps {
  onSubmit(event: React.FormEvent<HTMLFormElement>): void
  onChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChangeMessage(e: any): void
  loading: boolean
  message: MessageData
}

const MessageForm: React.FC<FormProps> = ({
  onSubmit,
  onChange,
  message,
  loading,
  onChangeMessage
}) => {
  const classes = useStyles()

  const history = useHistory()

  return (
    <form
      className={classes.root}
      autoComplete="off"
      onSubmit={event => onSubmit(event)}
    >
      <TextField
        label="Titulo da mensagem"
        variant="outlined"
        onChange={e => onChange(e)}
        name="title"
        required
        defaultValue={message ? message.title : ''}
      />
      <Editor
        initialValue={message ? message.content : ''}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help'
        }}
        apiKey="81p60xl5x9h8q8pyr0et0v9971j06ndc4t6hy34xk498lyz7"
        onChange={e => onChangeMessage(e)}
      />
      <Grid sm={12} justify="space-around" alignContent="space-around">
        <Button
          variant="outlined"
          color="primary"
          className={classes.btnBack}
          onClick={() => history.goBack()}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnSave}
          type="submit"
          disabled={loading}
        >
          Salvar
        </Button>
      </Grid>
    </form>
  )
}

export default MessageForm
