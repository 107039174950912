import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { COUNTER_SUBSCRIPTION, GET_COUNTER } from '../GraphQl/vote'
import { useQuery } from '@apollo/client'
import {
  ConfigurationMap,
  VoteCounter
} from '../configuration/configuration-types'
import { toast } from 'react-toastify'
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  Grid
} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    marginTop: 20
  },
  card: {
    width: '100%',
    textAlign: 'center'
  },
  title: {
    fontSize: 14
  }
})

interface PreviewCardProps {
  configurationMap: ConfigurationMap
}

interface VotesProps {
  votesCounter: VoteCounter
  votesCounterOnChanges: VoteCounter
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const VotingCounter: React.FC<PreviewCardProps> = ({ configurationMap }) => {
  const { loading, data, error, subscribeToMore, refetch } = useQuery<
    VotesProps
  >(GET_COUNTER, {
    variables: {
      category: configurationMap.screen?.category?._id
    },
    fetchPolicy: 'network-only'
  })
  const classes = useStyles()

  useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  useEffect(() => {
    subscribeToMore({
      document: COUNTER_SUBSCRIPTION,
      variables: {
        category: configurationMap.screen?.category?._id
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev
        return Object.assign({
          votesCounter: subscriptionData.data.votesCounterOnChanges
        })
      },
      onError: error => {
        toast.error(error.message)
      }
    })
  }, [configurationMap.screen, subscribeToMore])

  if (loading) {
    return <h1>Carregando...</h1>
  }

  if (error) {
    return <h1>{error.message}</h1>
  }

  return (
    <>
      <Typography variant="h5" className={classes.root} align="left">
        Informações sobre a votação atual, categoria{' '}
        {configurationMap.screen?.category?.name}
      </Typography>
      <br />
      <Card className={classes.card} variant="outlined">
        <CardContent>
          <Grid container justify="space-around">
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Cédulas
              </Typography>
              <Typography variant="h5" component="h2">
                {data?.votesCounter.ballots}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Escolhas
              </Typography>
              <Typography variant="h5" component="h2">
                {data?.votesCounter.count}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Votantes
              </Typography>
              <Typography variant="h5" component="h2">
                {data?.votesCounter.voter}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Progresso
              </Typography>
              <CircularProgressWithLabel
                value={data?.votesCounter.progress || 0}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default VotingCounter
