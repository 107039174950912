/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  Theme,
  Typography
} from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import {
  ConfigurationAppearanceAssetsInput,
  ConfigurationMap
} from './configuration-types'
enum UploadEnum {
  BACKGROUND = 'BACKGROUND',
  LOGO = 'LOGO',
  NONE = 'NONE'
}
interface DataMap {
  configurationMap: ConfigurationMap
}
interface MessageConfigurationProps {
  data: DataMap
  handleConfirm(backgroundImage: File, logo: File): void
  handleDeleteBackground(backgroundImage: null): void
  handleDeleteLogo(logo: null): void
  loading: boolean
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))(MuiDialogContent)

const AppearanceUpload: React.FC<MessageConfigurationProps> = ({
  data,
  handleConfirm,
  handleDeleteBackground,
  handleDeleteLogo,
  loading
}) => {
  const [openDeleter, setOpenDeleter] = useState<boolean>(false)
  const [deleteOption, setDeleteOption] = useState<UploadEnum>(UploadEnum.NONE)
  const [appearanceUploadDialog, setAppearanceUploadDialog] = useState<boolean>(
    false
  )
  const [assets, setAssets] = useState<ConfigurationAppearanceAssetsInput>({
    backgroundImage: null,
    logo: null
  })

  useEffect(() => {
    if (appearanceUploadDialog) {
      setAssets({ backgroundImage: null, logo: null })
    }
  }, [appearanceUploadDialog])

  const onChangeBackground = ({
    target: {
      validity,
      files: [file]
    }
  }: any) => {
    validity.valid && setAssets({ ...assets, backgroundImage: file })
  }
  const onChangeLogo = ({
    target: {
      validity,
      files: [file]
    }
  }: any) => {
    validity.valid && setAssets({ ...assets, logo: file })
  }
  const handleOpen = () => {
    setAppearanceUploadDialog(true)
  }

  const handleClose = () => {
    setAppearanceUploadDialog(false)
  }
  const confirmSave = () => {
    return handleConfirm(assets.backgroundImage, assets.logo)
  }

  return (
    <>
      {/* Messages section */}
      <Grid item>
        <Typography variant="h5" color="textPrimary" component="h5">
          Imagens
        </Typography>
        <br />
        <Grid item justify="space-around">
          <Fab
            color="primary"
            aria-label="message"
            onClick={handleOpen}
            style={{ fontSize: '1.5rem', textAlign: 'center' }}
          >
            <i className="fas fa-image" />
          </Fab>
        </Grid>
        {/* Show dialog */}
        <Dialog
          open={appearanceUploadDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Alterar configurações de aparência de imagens ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Alterando a aparência, você ira atualizar os atributos de
              estilização para todos os participantes em tempo real, algumas
              configurações podem atrapalhar a visualização do usúario.
            </DialogContentText>
            {data.configurationMap.appearance?.assets?.backgroundImage && (
              <>
                <img
                  src={
                    data.configurationMap.appearance?.assets?.backgroundImage
                  }
                  alt="imagem de fundo"
                  width="100px"
                  height="100px"
                  style={{ display: assets.backgroundImage ? 'none' : '' }}
                />
                <br />
              </>
            )}
            {assets.backgroundImage && (
              <>
                <img
                  src={URL.createObjectURL(assets.backgroundImage)}
                  alt="imagem de fundo"
                  width="100px"
                  height="100px"
                />
                <p>{assets.backgroundImage.name}</p>
                <br />
              </>
            )}
            <Button
              variant="contained"
              component="label"
              color="primary"
              disabled={loading}
            >
              Imagem de Fundo
              <input
                autoFocus
                name="backgroundImage"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={onChangeBackground}
              />
            </Button>
            <Button
              disabled={loading}
              variant="outlined"
              color="primary"
              onClick={() => {
                setAssets({ ...assets, backgroundImage: null })
                setAppearanceUploadDialog(false)
                setOpenDeleter(true)
                setDeleteOption(UploadEnum.BACKGROUND)
              }}
            >
              Deletar imagem de fundo
            </Button>
            <br />
            {data.configurationMap.appearance?.assets?.logo && (
              <>
                <img
                  src={data.configurationMap.appearance?.assets?.logo}
                  alt="imagem de logo"
                  width="100px"
                  height="100px"
                  style={{ display: assets.logo ? 'none' : '' }}
                />
                <br />
              </>
            )}
            {assets.logo && (
              <>
                <img
                  src={URL.createObjectURL(assets.logo)}
                  alt="imagem de logo"
                  width="100px"
                  height="100px"
                />
                <p>{assets.logo.name}</p>
                <br />
              </>
            )}
            <Button
              variant="contained"
              component="label"
              color="primary"
              disabled={loading}
            >
              Imagem de Logo
              <input
                name="logo"
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={onChangeLogo}
              />
            </Button>
            <Button
              disabled={loading}
              variant="outlined"
              color="primary"
              onClick={() => {
                setAssets({ ...assets, logo: null })
                setAppearanceUploadDialog(false)
                setOpenDeleter(true)
                setDeleteOption(UploadEnum.LOGO)
              }}
            >
              Deletar imagem de logo
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                confirmSave()
                handleClose()
              }}
              color="primary"
            >
              Atualizar imagens!
            </Button>
          </DialogActions>
        </Dialog>
        {/* Delete dialog */}
        <Dialog
          open={openDeleter}
          onClose={() => setOpenDeleter(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Deletar imagem ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Tem certeza que deseja deletar a imagem ? Esta ação não pode ser
              desfeita.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDeleter(false)} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                setOpenDeleter(false)
                if (deleteOption === UploadEnum.BACKGROUND) {
                  return handleDeleteBackground(null)
                } else if (deleteOption === UploadEnum.LOGO) {
                  return handleDeleteLogo(null)
                }
              }}
              color="primary"
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  )
}

export default AppearanceUpload
