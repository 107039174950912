import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Button, Grid } from '@material-ui/core'
import { Practice } from './practices-types'
import { GET_CATEGORIES } from '../GraphQl/categories'
import { Autocomplete } from '@material-ui/lab'
import { Category } from '../Categories/category-types'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    btnBack: {
      padding: '16px',
      width: '40%',
      margin: '0 10% 0 0'
    },
    btnSave: {
      padding: '16px',
      width: '40%',
      margin: '0 0 0 10%'
    }
  })
)

interface FormProps {
  onSubmit(event: React.FormEvent<HTMLFormElement>): void
  onChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void
  categoryChange(categoryId: string | null): void
  practice?: Practice
  loading: boolean
}

const PracticeForm: React.FC<FormProps> = ({
  onSubmit,
  onChange,
  categoryChange,
  practice,
  loading
}) => {
  const { loading: loadingCategories, data, error } = useQuery(GET_CATEGORIES)
  const classes = useStyles()
  const history = useHistory()

  if (loadingCategories) {
    return <h1>Carregando...</h1>
  }

  if (error) {
    console.log(error.message)
  }

  return (
    <form
      className={classes.root}
      autoComplete="off"
      onSubmit={event => onSubmit(event)}
    >
      <TextField
        label="Nome da prática"
        variant="outlined"
        onChange={e => onChange(e)}
        name="name"
        required
        defaultValue={practice ? practice.name : ''}
      />
      <TextField
        label="Numero da prática"
        type="number"
        variant="outlined"
        onChange={e => onChange(e)}
        name="number"
        required
        defaultValue={practice ? practice.number : ''}
      />
      <Autocomplete
        id="combo-box-demo"
        options={data.categories.edges}
        getOptionLabel={(option: Category) => option.name}
        onChange={(e, newValue) =>
          categoryChange(newValue ? newValue._id : null)
        }
        defaultValue={practice?.category}
        renderInput={params => (
          <TextField
            {...params}
            label="Categoria"
            variant="outlined"
            name="category"
            required
          />
        )}
      />
      <Grid sm={12} justify="space-around" alignContent="space-around">
        <Button
          variant="outlined"
          color="primary"
          className={classes.btnBack}
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnSave}
          type="submit"
          disabled={loading}
        >
          Salvar
        </Button>
      </Grid>
    </form>
  )
}

export default PracticeForm
