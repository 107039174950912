import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import AwardForm from '../../components/awards/form-awards'
import { AwardData } from '../../components/awards/award-types'
import { Category } from '../../components/Categories/category-types'
import { ADD_AWARD } from '../../components/GraphQl/awards'

const AwardNew: React.FC = () => {
  const [createAward, { loading }] = useMutation(ADD_AWARD)

  const history = useHistory()

  const [award, setAward] = useState<AwardData>({
    name: '',
    weight: 0,
    categories: []
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const categories = award.categories.map(categorie => categorie._id)

    createAward({
      variables: {
        input: {
          name: award.name,
          weight: award.weight,
          categories: categories
        }
      }
    }).then(() => {
      history.push('/admin/premios')
    })
  }

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    if (name === 'weight') {
      return setAward(practice => ({ ...practice, [name]: Number(value) }))
    }
    setAward(practice => ({ ...practice, [name]: value }))
  }

  const handleCategoryChange = (categoryId: Category[]) => {
    setAward(practice => ({ ...practice, categories: categoryId }))
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Prêmios
      </Typography>
      <Grid item xs={12}>
        <AwardForm
          loading={loading}
          onSubmit={event => handleSubmit(event)}
          onChange={handleChange}
          categoryChange={handleCategoryChange}
          award={award}
        />
      </Grid>
    </>
  )
}

export default AwardNew
