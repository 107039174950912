import {
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import React, { useEffect } from 'react'
import {
  Participant,
  ParticipantConnectionEnum,
  ParticipantRoleEnum
} from '../configuration/configuration-types'
import ParticipantCard from './participant-card'
import { Autorenew } from '@material-ui/icons'
import { ParticipantNoVoterCard } from './participant-no-voter-card'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      },
      minHeight: 50
    },
    card: {
      width: '100%'
    },
    appBar: {
      position: 'relative'
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    searchItem: {
      marginRight: 15
    },
    legend: {
      '& > *': {
        margin: 5
      }
    },
    connection: {
      backgroundColor: '#333',
      padding: 5,
      color: 'white'
    },
    av: {
      backgroundColor: '#333',
      padding: 5,
      color: 'white'
    },
    avIcon: {
      background: '#fff',
      padding: '4px',
      borderRadius: '50%',
      width: '23px',
      height: '23px',
      lineHeight: '18px',
      textAlign: 'center'
    }
  })
)

interface ParticipantListProps {
  loading: boolean
  participants: Participant[]
  refetch: () => void
}

interface Filters {
  name: string
  status: ParticipantConnectionEnum | string
  voted: boolean | string
}

export const ParticipantList: React.FC<ParticipantListProps> = ({
  loading,
  participants,
  refetch
}) => {
  const [filteredParticipants, setFilteredParticipants] = React.useState<
    Participant[]
  >([])
  const [filter, setFilter] = React.useState<Filters>({
    name: '',
    status: '',
    voted: ''
  })
  const classes = useStyles()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    if (e.target.name === 'name') {
      setFilter({ ...filter, name: e.target.value })
    } else if (e.target.name === 'status') {
      setFilter({
        ...filter,
        status:
          e.target.value === 'null'
            ? JSON.parse(e.target.value)
            : (e.target.value as ParticipantConnectionEnum | null)
      })
    } else if (e.target.name === 'voted') {
      setFilter({
        ...filter,
        // voted: JSON.parse(e.target.value as string)
        voted: e.target.value ? JSON.parse(e.target.value as string) : null
      })
    }
  }
  useEffect(() => {
    let newArray: Participant[] = participants
    if (participants === undefined) {
      return
    }

    if (filter.name !== '') {
      newArray = newArray.filter(participant =>
        participant.name.toLowerCase().includes(filter.name.toLowerCase())
      )
    }

    if (filter.status !== '') {
      newArray = newArray.filter(
        participant => participant.connection === filter.status
      )
    }

    if (filter.voted !== '') {
      if (filter.voted === true) {
        newArray = newArray.filter(
          participant =>
            participant.votedPractices &&
            participant.votedPractices[0] !== undefined
        )
      }
      if (filter.voted === false) {
        newArray = newArray.filter(
          participant =>
            participant.votedPractices &&
            participant.votedPractices[0] === undefined
        )
      }
    }
    setFilteredParticipants(newArray)
  }, [filter, participants])

  if (loading) {
    return <h1>Carregando...</h1>
  }

  return (
    <>
      <Typography variant="h5" align="left">
        Informações sobre os Participantes
      </Typography>
      <br />
      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <Typography variant="h4" align="left">
            Participantes {filteredParticipants.length}
          </Typography>
          <br />
          <Grid container>
            <Grid item className={classes.searchItem}>
              <InputLabel id="status-filter-name">Buscar por nome:</InputLabel>
              <TextField
                id="status-filter-name"
                variant="outlined"
                name="name"
                value={filter.name}
                onChange={e => handleChange(e)}
                style={{ width: '165px' }}
                InputProps={{
                  startAdornment: (
                    <i
                      className="fas fa-search "
                      style={{ color: '#555', marginRight: 5 }}
                    />
                  )
                }}
              />
            </Grid>
            <Grid item className={classes.searchItem}>
              <InputLabel id="status-filter-select">
                Filtro por status:
              </InputLabel>
              <Select
                id="status-filter-select"
                onChange={e => handleChange(e)}
                name="status"
                variant="outlined"
                value={filter.status}
                style={{ width: '165px' }}
              >
                <MenuItem value={''}>Todos os status</MenuItem>
                <MenuItem value={ParticipantConnectionEnum.Away}>
                  Ausente
                </MenuItem>
                <MenuItem value={ParticipantConnectionEnum.Offline}>
                  Offline
                </MenuItem>
                <MenuItem value={ParticipantConnectionEnum.Online}>
                  Online
                </MenuItem>
              </Select>
            </Grid>
            <Grid item className={classes.searchItem}>
              <InputLabel id="status-filter-select">
                Filtro por voto:
              </InputLabel>
              <Select
                id="status-filter-select"
                onChange={e => handleChange(e)}
                name="voted"
                variant="outlined"
                value={filter.voted}
                style={{ width: '165px' }}
              >
                <MenuItem value={''}>Todos os votantes</MenuItem>
                <MenuItem value={'false'}>Não votou</MenuItem>
                <MenuItem value={'true'}>Votou</MenuItem>
              </Select>
            </Grid>
            <Grid item >
              <Button
                color="primary"
                size="large"
                variant="outlined"
                onClick={() =>
                  setFilter({ name: '', status: '', voted: '' })
                }
                style={{ margin: '25px 0' }}
              >
                Limpar filtros
              </Button>
              <IconButton onClick={refetch} color="primary">
                <Autorenew />
              </IconButton>
            </Grid>
          </Grid>
          <Typography variant="h5" align="left">
            Exibindo{' '}
            {filteredParticipants &&
              filteredParticipants.filter(
                fP => fP.role === ParticipantRoleEnum.Voter
              ).length}{' '}
            VOTANTES
          </Typography>
          <Grid container justify="flex-start" className={classes.root}>
            {filteredParticipants &&
              filteredParticipants.map(participant => {
                if (participant.role !== ParticipantRoleEnum.Voter) {
                  return ''
                }
                return (
                  <Grid item key={participant._id}>
                    <ParticipantCard participant={participant} />
                  </Grid>
                )
              })}
          </Grid>
          <hr />
          <Typography variant="h5" align="left">
            Exibindo{' '}
            {filteredParticipants &&
              filteredParticipants.filter(
                fP => fP.role === ParticipantRoleEnum.Viewer
              ).length}{' '}
            ESPECTADORES
          </Typography>
          <Grid container justify="flex-start" className={classes.root}>
            {filteredParticipants &&
              filteredParticipants.map(participant => {
                if (participant.role !== ParticipantRoleEnum.Viewer) {
                  return ''
                }
                return (
                  <Grid item key={participant._id}>
                    <ParticipantNoVoterCard participant={participant} />
                  </Grid>
                )
              })}
          </Grid>
          <hr />
          <Typography variant="h5" align="left">
            Exibindo{' '}
            {filteredParticipants &&
              filteredParticipants.filter(
                fP => fP.role === ParticipantRoleEnum.God
              ).length}{' '}
            Representantes
          </Typography>
          <Grid container justify="flex-start" className={classes.root}>
            {filteredParticipants &&
              filteredParticipants.map(participant => {
                if (participant.role !== ParticipantRoleEnum.God) {
                  return ''
                }
                return (
                  <Grid item key={participant._id}>
                    <ParticipantNoVoterCard participant={participant} />
                  </Grid>
                )
              })}
          </Grid>
          <hr />
          <Typography variant="h5" align="left">
            Legenda
          </Typography>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            className={classes.legend}
          >
            <Grid item>
              <div
                style={{
                  backgroundColor: '#1d3959',
                  color: 'white',
                  padding: 5
                }}
              >
                VOTOU
              </div>
            </Grid>
            <Grid item>
              <div
                style={{
                  backgroundColor: '#a8dadc',
                  color: 'black',
                  padding: 5
                }}
              >
                NÃO VOTOU
              </div>
            </Grid>
            <Grid item className={classes.connection}>
              Ausente
              <i
                className="fas fa-question-circle"
                style={{ color: '#F57F02FF', marginLeft: 2 }}
              />
            </Grid>
            <Grid item className={classes.connection}>
              Offline
              <i
                className="fas fa-times-circle"
                style={{ color: '#f7052e', marginLeft: 2 }}
              />
            </Grid>
            <Grid item className={classes.connection}>
              Online
              <i
                className="fas fa-signal"
                style={{ color: '#0b821a', marginLeft: 2 }}
              />
            </Grid>
            <div
              style={{
                backgroundColor: '#1d3959',
                color: 'white',
                padding: 5
              }}
            >
              Voto: XX numero da prática
            </div>
            <div
              style={{
                backgroundColor: '#1d3959',
                color: 'white',
                padding: 5
              }}
            >
              Voto: --- EM BRANCO
            </div>

            <Grid item className={classes.av}>
              Remoto
              <i
                className={`fas fa-columns ${classes.avIcon}`}
                style={{ color: '#0b821a', marginLeft: 2 }}
              />
            </Grid>
            <Grid item className={classes.av}>
              Presencial
              <i
                className={`fas fa-desktop ${classes.avIcon}`}
                style={{ color: '#f7052e', marginLeft: 2 }}
              />
            </Grid>
            <Grid item className={classes.av}>
              Pendente
              <i
                className={`fas fa-hourglass-half ${classes.avIcon}`}
                style={{ color: '#F57F02FF', marginLeft: 2 }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default ParticipantList
