import {
  createStyles,
  Fab,
  Grid,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import React, { useState } from 'react'
import { Category } from '../Categories/category-types'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import PauseIcon from '@material-ui/icons/Pause'
import VideocamIcon from '@material-ui/icons/Videocam'
import SlideshowIcon from '@material-ui/icons/Slideshow'
import InfoIcon from '@material-ui/icons/Info'
import {
  ConfigurationMap,
  ConfigurationMessage,
  ConfigurationScreenOptionsInput,
  ConfigurationScreenTypeEnum,
  CategoryStatusEnum
} from '../configuration/configuration-types'
import ClearVote from './vote-clear'
import ResultPreview from './results-preview'
import MessageConfiguration from '../configuration/message-config'
import { useMutation, useQuery } from '@apollo/client'
import { GET_MESSAGES } from '../GraphQl/messages'
import { CONFIGURATION_MESSAGE } from '../GraphQl/configurations'

interface CategoryActionsProps {
  category?: Category
  configurationMap: ConfigurationMap
  openVoting(category: string): void
  closeVoting(): void
  loading: boolean
  setScreen(
    screen: ConfigurationScreenTypeEnum,
    options?: ConfigurationScreenOptionsInput
  ): void
  startAutoResult(): void
  stopAutoResult(): void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    }
  })
)

const CategoryActions: React.FC<CategoryActionsProps> = ({
  category,
  configurationMap,
  openVoting,
  closeVoting,
  loading,
  setScreen,
  startAutoResult,
  stopAutoResult
}) => {
  const { data, loading: loadingMessages } = useQuery(GET_MESSAGES)
  const [setConfigMessage] = useMutation(CONFIGURATION_MESSAGE)
  const [autoResult, setAutoResult] = useState<boolean>(false)
  const classes = useStyles()

  const handleVoting = () => {
    if (
      configurationMap.screen?.category?.status &&
      configurationMap.screen?.category?.status === CategoryStatusEnum.Voting
    ) {
      closeVoting()
    } else {
      category && openVoting(category?._id)
    }
  }

  const messageConfirm = (currentMessage: ConfigurationMessage) => {
    setConfigMessage({
      variables: {
        inputConfigMessage: {
          title: currentMessage.title,
          content: currentMessage.content
        }
      }
    }).then(() => {
      setScreen(ConfigurationScreenTypeEnum.Message)
    })
  }

  const setVoteBtn = () => {
    if (
      configurationMap.screen?.category?.status &&
      configurationMap.screen?.category?.status === CategoryStatusEnum.Voting
    ) {
      return (
        <>
          <PauseIcon />
          Encerrar Votação
        </>
      )
    } else {
      return (
        <>
          <PlayArrowIcon />
          Iniciar Votação
        </>
      )
    }
  }

  return (
    <>
      <Typography variant="h5" align="left">
        Votação
      </Typography>
      <Grid container className={classes.root}>
        <Grid item className={classes.root}>
          {category && <ClearVote loading={loading} category={category} />}
          <Fab
            color={
              configurationMap.screen?.category?.status &&
              configurationMap.screen?.category?.status ===
                CategoryStatusEnum.Voting
                ? 'default'
                : 'primary'
            }
            size="large"
            aria-label="votacao"
            variant="extended"
            className={classes.extendedIcon}
            onClick={handleVoting}
            disabled={loading}
          >
            {setVoteBtn()}
          </Fab>
          <Fab
            color={
              configurationMap.screen?.screenType ===
                ConfigurationScreenTypeEnum.Voting &&
              configurationMap.screen?.category?._id === category?._id
                ? 'default'
                : 'primary'
            }
            size="large"
            aria-label="tela da votacao"
            variant="extended"
            className={classes.extendedIcon}
            onClick={() => {
              setScreen(ConfigurationScreenTypeEnum.Voting, {
                category: category?._id
              })
            }}
            disabled={
              loading ||
              (configurationMap.screen?.screenType ===
                ConfigurationScreenTypeEnum.Voting &&
                configurationMap.screen?.category?._id === category?._id)
            }
          >
            <VideocamIcon />
            {configurationMap.screen?.screenType ===
              ConfigurationScreenTypeEnum.Voting &&
            configurationMap.screen?.category?._id === category?._id
              ? 'Exibindo para votação'
              : 'Exibir para votação'}
          </Fab>
        </Grid>
      </Grid>
      <Typography variant="h5" align="left">
        Resultado
      </Typography>
      <Grid container className={classes.root}>
        <Grid item className={classes.root}>
          <Fab
            color="primary"
            size="large"
            aria-label="capa resultado"
            variant="extended"
            className={classes.extendedIcon}
            onClick={() => {
              setScreen(ConfigurationScreenTypeEnum.Cover, {
                category: category?._id
              })
            }}
            disabled={
              loading ||
              (configurationMap.screen?.screenType ===
                ConfigurationScreenTypeEnum.Cover &&
                configurationMap.screen?.category?._id === category?._id)
            }
          >
            <SlideshowIcon />
            Mostrar Capa
          </Fab>
          {category && (
            <ResultPreview
              screen={configurationMap.screen || undefined}
              category={category}
              loading={loading}
              setScreen={setScreen}
            />
          )}
          <Fab
            color="primary"
            size="large"
            aria-label="capa resultado"
            variant="extended"
            className={classes.extendedIcon}
            onClick={() => {
              setAutoResult(!autoResult)
              if (autoResult) {
                stopAutoResult()
              } else {
                startAutoResult()
              }
            }}
            disabled={loading}
          >
            {autoResult ? <PauseIcon /> : <SlideshowIcon />}
            {autoResult
              ? 'Parar reprodução automatica de resultado'
              : 'Reprodução automatica de resultado'}
          </Fab>
        </Grid>
      </Grid>
      <Typography variant="h5" align="left">
        Telas
      </Typography>
      <Grid container className={classes.root}>
        <Fab
          color="primary"
          size="large"
          aria-label="tela vazia logo"
          variant="extended"
          className={classes.extendedIcon}
          onClick={() => {
            setScreen(ConfigurationScreenTypeEnum.Empty)
          }}
          disabled={
            loading ||
            configurationMap.screen?.screenType ===
              ConfigurationScreenTypeEnum.Empty
          }
        >
          <InfoIcon />
          Mostrar tela de logo
        </Fab>
        {!loadingMessages && (
          <MessageConfiguration
            messagesData={data && data}
            data={{ configurationMap }}
            handleConfirm={messageConfirm}
            disabled={loadingMessages}
            extended
          />
        )}
      </Grid>
    </>
  )
}

export default CategoryActions
