import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { ADD_PRACTICE } from '../../components/GraphQl/practices'
import { useHistory } from 'react-router-dom'
import PracticeForm from '../../components/Practices/form-practices'
import { PracticeData } from '../../components/Practices/practices-types'

const PracticeNew: React.FC = () => {
  const [createPractice, { loading }] = useMutation(ADD_PRACTICE)

  const history = useHistory()

  const [practice, setPractice] = useState<PracticeData>({
    name: '',
    number: 0,
    category: ''
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    createPractice({ variables: { inputPractice: practice } }).then(() => {
      history.push('/admin/praticas')
    })
  }

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    if (name === 'number') {
      return setPractice(practice => ({ ...practice, [name]: Number(value) }))
    }
    setPractice(practice => ({ ...practice, [name]: value }))
  }

  const handleCategoryChange = (categoryId: string | null) => {
    setPractice(practice => ({ ...practice, category: categoryId }))
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Práticas
      </Typography>
      <Grid item xs={12}>
        <PracticeForm
          loading={loading}
          onSubmit={event => handleSubmit(event)}
          onChange={handleChange}
          categoryChange={handleCategoryChange}
        />
      </Grid>
    </>
  )
}

export default PracticeNew
