import React from 'react'
import { ParticipantConnectionEnum } from '../configuration/configuration-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  iconBox: {
    background: '#fff',
    padding: '4px',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    lineHeight: '15px',
    textAlign: 'center',
    fontSize: '10px'
  }
})

type Props = {
  connection: ParticipantConnectionEnum
}
const IconConnection: React.FC<Props> = ({ connection }) => {
  const classes = useStyles()

  switch (connection) {
    case ParticipantConnectionEnum.Online:
      return (
        <div className={classes.iconBox}>
          <i className="fas fa-signal" style={{ color: '#0b821a' }} />
        </div>
      )
    case ParticipantConnectionEnum.Offline:
      return (
        <div className={classes.iconBox}>
          {' '}
          <i className="fas fa-times-circle" style={{ color: '#f7052e' }} />
        </div>
      )
    case ParticipantConnectionEnum.Away:
      return (
        <div className={classes.iconBox}>
          {' '}
          <i
            className="fas fa-question-circle"
            style={{ color: '#F57F02FF' }}
          />
        </div>
      )
  }
}
export default IconConnection
