import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  GET_PRACTICE,
  UPDATE_PRACTICE
} from '../../components/GraphQl/practices'
import { PracticeData } from '../../components/Practices/practices-types'
import PracticeForm from '../../components/Practices/form-practices'

interface MatchParams {
  id: string
}

type PracticeEditProps = RouteComponentProps<MatchParams>

const PracticeEdit: React.FC<PracticeEditProps> = ({ match }) => {
  const [updatePractice, { loading: loadingUpdate }] = useMutation(
    UPDATE_PRACTICE
  )
  const { id } = match.params

  const { data, loading, error } = useQuery(GET_PRACTICE, {
    variables: {
      id
    }
  })

  const history = useHistory()

  const [practice, setPractice] = useState<PracticeData>({
    name: '',
    number: 0,
    category: ''
  })

  useEffect(() => {
    if (data && data.practice && data.practice.category) {
      setPractice({
        name: data.practice.name,
        number: data.practice.number,
        category: data.practice.category._id
      })
    }
  }, [data])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    updatePractice({
      variables: {
        id,
        inputUpdate: {
          name: practice.name,
          number: practice.number,
          category: practice.category
        }
      }
    }).then(() => {
      history.push('/admin/praticas')
    })
  }

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    if (name === 'number' || name === 'quantityWin') {
      return setPractice(practice => ({ ...practice, [name]: Number(value) }))
    }
    setPractice(practice => ({ ...practice, [name]: value }))
  }

  const handleCategoryChange = (categoryId: string | null) => {
    setPractice(practice => ({ ...practice, category: categoryId }))
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Prática {(data && data.practice && data.practice.name) || ''}
      </Typography>
      <Grid item xs={12}>
        {error && <h1>{error.message}</h1>}
        {loading ? (
          <h1>Carregando...</h1>
        ) : (
          <PracticeForm
            loading={loadingUpdate}
            onSubmit={event => handleSubmit(event)}
            onChange={handleChange}
            categoryChange={handleCategoryChange}
            practice={data.practice}
          />
        )}
      </Grid>
    </>
  )
}

export default PracticeEdit
