import { gql } from '@apollo/client'

export const GET_MESSAGES = gql`
  query Messages($paramsMessages: MessageSearchInput) {
    messages(params: $paramsMessages) {
      edges {
        _id
        title
        content
      }
      pagination {
        total
        page
      }
    }
  }
`
export const GET_MESSAGE = gql`
  query Message($id: ID!) {
    message(id: $id) {
      _id
      title
      content
    }
  }
`

export const ADD_MESSAGE = gql`
  mutation CreateMessage($input: MessageCreateInput!) {
    createMessage(input: $input) {
      message
      data {
        _id
        title
        content
      }
    }
  }
`
export const UPDATE_MESSAGE = gql`
  mutation UpdateMessage($id: ID!, $inputUpdate: MessageUpdateInput!) {
    updateMessage(id: $id, input: $inputUpdate) {
      message
      data {
        _id
        title
        content
      }
    }
  }
`
export const DELETE_MESSAGE = gql`
  mutation DeleteMessages($ids: [ID!]!) {
    deleteMessage(ids: $ids) {
      message
    }
  }
`
