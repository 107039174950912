export enum CategoryStatusEnum {
  PENDENT = 'PENDENT',
  VOTING = 'VOTING',
  VOTED = 'VOTED'
}
export interface Category {
  _id: string
  name: string
  number: number
  quantityWin: number
  status: CategoryStatusEnum
}

export interface Data {
  categories: {
    edges: Category[]
  }
}

export interface Categories {
  data: Data
  loading: boolean
}

export interface CategoryData {
  name: string
  number: number
  quantityWin: number
}
