import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import CardDashboard from '../../components/Dashboard/card'
import TableDashboard from '../../components/Dashboard/table'
import { useQuery } from '@apollo/client'
import { GET_CATEGORIES } from '../../components/GraphQl/categories'
import { useKeycloak } from '@react-keycloak/web'

const Dashboard: React.FC = () => {
  const [keycloak] = useKeycloak()
  const { data, loading } = useQuery(GET_CATEGORIES)

  return (
    <>
      <Typography variant="h4" color="textPrimary" align="left">
        Relatórios
      </Typography>
      <br />
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} sm={6} md={3}>
          <CardDashboard title="Resultados gerais">
            <div
              onClick={() =>
                window.open(
                  `https://services.votepraticasinnovare.com.br/reports/pdf/results?token=${keycloak.token}`,
                  '_blank'
                )
              }
              style={{
                cursor: 'pointer'
              }}
            >
              <i className="fas fa-file-pdf" style={{ fontSize: '1.5rem' }} />
            </div>
          </CardDashboard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CardDashboard title="Ranking categorias">
            <div
              onClick={() =>
                window.open(
                  `https://services.votepraticasinnovare.com.br/reports/xls/ranking?token=${keycloak.token}`,
                  '_blank'
                )
              }
              style={{
                cursor: 'pointer'
              }}
            >
              <i className="fas fa-file-excel" style={{ fontSize: '1.5rem' }} />
            </div>
          </CardDashboard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CardDashboard title="Extrato de votos">
            <div
              onClick={() =>
                window.open(
                  `https://services.votepraticasinnovare.com.br/reports/xls/statements?token=${keycloak.token}`,
                  '_blank'
                )
              }
              style={{
                cursor: 'pointer'
              }}
            >
              <i className="fas fa-file-excel" style={{ fontSize: '1.5rem' }} />
            </div>
          </CardDashboard>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" color="textPrimary" component="h2">
            Categorias
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {loading ? <h1>Carregando...</h1> : <TableDashboard data={data} />}
        </Grid>
      </Grid>
    </>
  )
}

export default Dashboard
