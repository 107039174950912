import { gql } from '@apollo/client'

export const GET_CATEGORIES = gql`
  query Categories($params: CategorySearchInput) {
    categories(params: $params) {
      edges {
        _id
        name
        number
        quantityWin
        status
      }
      pagination {
        total
        page
        perPage
        lastPage
      }
    }
  }
`

export const GET_CATEGORY = gql`
  query Category($id: ID!) {
    category(id: $id) {
      _id
      name
      number
      quantityWin
    }
  }
`

export const ADD_CATEGORY = gql`
  mutation AddCategory($input: CategoryCreateInput!) {
    createCategory(input: $input) {
      message
      data {
        _id
        name
        number
        quantityWin
      }
    }
  }
`

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: ID!, $inputUpdate: CategoryUpdateInput!) {
    updateCategory(id: $id, input: $inputUpdate) {
      message
      data {
        _id
        name
        number
        quantityWin
      }
    }
  }
`

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($ids: [ID!]!) {
    deleteCategory(ids: $ids) {
      message
    }
  }
`
export const IMPORT_CATEGORIES = gql`
  mutation ImportParticipants($file: Upload!) {
    importCategoriesWithPractices(file: $file) {
      message
    }
  }
`
