import React from 'react'
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core'

type CardProps = {
  title: string
  backgroundColor?: string
}

const CardDashboard: React.FC<CardProps> = ({
  title,
  backgroundColor,
  children
}) => {
  return (
    <Card style={{ backgroundColor: backgroundColor || '', height: '200px' }}>
      <CardHeader title={title} />
      <CardContent>
        <Typography color="textPrimary" component="h1" align="center">
          {children}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default CardDashboard
