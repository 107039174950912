import { useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import { WAIT_LIST, WAIT_LIST_ON_CHANGE } from '../GraphQl/participants'

export type WaitingRoom = {
  APPROVED: number
  PENDING: number
  BLOCKED: number
}

export type Props = {
  waitingRoom?: WaitingRoom
  setWaitingRoom?: (waitingRoom: WaitingRoom | undefined) => void
}

export type WaitingRoomProps = {
  other?: string
} & Props

export const WaitingRoomContext = React.createContext<WaitingRoomProps>({})

export const WaitingRoomProvider: React.FC = ({ children }) => {
  const { data, subscribeToMore } = useQuery(WAIT_LIST)

  useEffect(() => {
    if (data) {
      const unsubscribre = subscribeToMore({
        document: WAIT_LIST_ON_CHANGE,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData) return prev
          if (subscriptionData.data.waitingRoomOnChange.message) {
            toast.warning(subscriptionData.data.waitingRoomOnChange.message)
          }
          return {
            waitingRoom: subscriptionData.data.waitingRoomOnChange.status
          }
        },
        onError: error => {
          toast.error(error.message)
        }
      })
      return () => unsubscribre()
    }
  }, [data, subscribeToMore])

  return (
    <WaitingRoomContext.Provider value={data}>
      {children}
    </WaitingRoomContext.Provider>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useWaitingRoom = () => React.useContext(WaitingRoomContext)
