import { gql } from '@apollo/client'

export const SET_SCREEN = gql`
  mutation SetScreen(
    $screen: ConfigurationScreenTypeEnum!
    $options: ConfigurationScreenOptionsInput
  ) {
    setConfigScreen(screen: $screen, options: $options) {
      message
    }
  }
`
export const OPEN_VOTING = gql`
  mutation OpenVoting($category: ID!) {
    openVoting(category: $category) {
      message
    }
  }
`
export const CLOSE_VOTING = gql`
  mutation CloseVoting {
    closeVoting {
      message
    }
  }
`

export const GET_COUNTER = gql`
  query Counter($category: ID!) {
    votesCounter(category: $category) {
      ballots
      count
      participants
      voter
      progress
    }
  }
`
export const CLEAR_VOTE = gql`
  mutation ClearAllVoting($category: ID!, $action: String!) {
    clearAllVotes(category: $category, action: $action) {
      message
    }
  }
`
export const COUNTER_SUBSCRIPTION = gql`
  subscription CounterOnChange($category: ID!) {
    votesCounterOnChanges(category: $category) {
      ballots
      count
      participants
      voter
      progress
    }
  }
`

export const GET_RESULTS = gql`
  query Results($category: ID!) {
    votesResult(category: $category) {
      category {
        _id
        name
      }
      hasDraw
      practices {
        _id
        name
        number
      }
    }
  }
`
export const START_RESULT = gql`
  mutation StartResult {
    startAutoResult {
      message
    }
  }
`
export const STOP_RESULT = gql`
  mutation StopResult {
    stopAutoResult {
      message
    }
  }
`
export const RESULT_PARTICIPANT = gql`
  query Participants($params: ParticipantSearchInput, $category: ID) {
    participants(params: $params) {
      edges {
        _id
        keypad
        login
        name
        status
        connection
        role
        votedPractices(category: $category)
        av
      }
    }
  }
`
export const SUBSCRIPTION_RESULT_PARTICIPANT = gql`
  subscription ParticipantsOnChange($params: ParticipantSearchInput) {
    participantOnChanges(params: $params) {
      _id
      action
      data {
        _id
        keypad
        login
        name
        status
        role
        connection
        av
      }
    }
  }
`

export const SUBSCRIPTION_NEW_VOTE = gql`
  subscription newVote {
    onNewVote {
      participant
      participantLogin
      category
      categoryName
      votedPractices
      usernameGod
      participantKeypad
    }
  }
`
