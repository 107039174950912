import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import UserForm from '../../components/Users/form'
import { ParticipantData } from '../../components/Users/participant-types'
import { ADD_PARTICIPANT } from '../../components/GraphQl/participants'

const ParticipantNew: React.FC = () => {
  const [createParticipant, { loading }] = useMutation(ADD_PARTICIPANT)

  const history = useHistory()

  const [participant, setParticipant] = useState<ParticipantData>({
    name: '',
    role: 'VOTER',
    login: '',
    keypad: '',
    password: ''
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (participant.role === '') {
      return toast.error('Escolha uma função para o participante')
    }

    createParticipant({ variables: { input: participant } }).then(() => {
      history.push('/admin/usuarios')
    })
  }

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    setParticipant(participant => ({ ...participant, [name]: value }))
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Participante
      </Typography>
      <Grid item xs={12}>
        <UserForm
          loading={loading}
          onSubmit={event => handleSubmit(event)}
          onChange={handleChange}
          data={participant}
        />
      </Grid>
    </>
  )
}

export default ParticipantNew
