import React from 'react'
import { HashRouter as Router, Redirect, Route } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'
import { PrivateRoute } from './utils'
import Home from '../pages/Home'
import Private from './private'
import { ReactComponent as LoadingSvg } from '../components/assets/loading.svg'
import logo from '../components/assets/logo.png'
import { WebSocketLink } from '@apollo/client/link/ws'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { toast } from 'react-toastify'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  ApolloLink
} from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { WaitingRoomProvider } from '../components/Users/provider'
import { UserProvider } from './userValidation'
import BlockedPage from '../components/blocked/blocked'
interface Definintion {
  kind: string
  operation?: string
}

export const AppRouter: React.FC = () => {
  const [keycloak, initialized] = useKeycloak()

  const token = keycloak?.token

  const config = {
    uri:
      process.env.REACT_APP_GRAPHQL_URI ||
      'https://services.votepraticasinnovare.com.br/graphql',
    ws:
      process.env.REACT_APP_GRAPHQL_WS ||
      'wss://services.votepraticasinnovare.com.br/graphql'
  }

  const wsLink = new WebSocketLink({
    uri: config.ws,
    options: {
      timeout: 6000,
      reconnect: true,
      connectionParams: {
        authorization: token ? `Bearer ${token}` : undefined
      }
    }
  })

  const httpLink = createUploadLink({
    uri: config.uri,
    headers: {
      authorization: token ? `Bearer ${token}` : undefined
    }
  })

  const link = split(
    ({ query }) => {
      const { kind, operation }: Definintion = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    wsLink,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    httpLink
  )

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, extensions }) => {
        if (extensions && extensions.code === 'E_UNAUTHORIZED') {
          setTimeout(() => {
            if (keycloak) {
              return keycloak?.logout()
            }
          }, 1500)
        }
        return toast.error(message)
      })
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
  })

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    link: ApolloLink.from([errorLink, link])
  })

  if (!initialized) {
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          backgroundColor: '#fff',

          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <img src={logo} alt="Prêmio Innovare" />
        <div>
          <LoadingSvg />
        </div>
      </div>
    )
  }

  return (
    <Router>
      <Redirect from="/" to="/admin" />
      <Route path="/home" exact component={Home} />
      <Route path="/blocked" exact component={BlockedPage} />
      <ApolloProvider client={client}>
        <UserProvider>
          <WaitingRoomProvider>
            <PrivateRoute path="/admin" component={Private} />
          </WaitingRoomProvider>
        </UserProvider>
      </ApolloProvider>
    </Router>
  )
}
