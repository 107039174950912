import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import CardDashboard from '../../components/Dashboard/card'
import GetAppIcon from '@material-ui/icons/GetApp'
import UsersTableList from '../../components/Users/table'
import { useHistory } from 'react-router-dom'
import {
  DELETE_PARTICIPANT,
  GET_PARTICIPANTS,
  IMPORT_PARTICIPANT,
  STATUS_PARTICIPANT
} from '../../components/GraphQl/participants'
import { useMutation, useQuery } from '@apollo/client'
import { ParticipantBulkActionEnum } from '../../components/configuration/configuration-types'
import { toast } from 'react-toastify'

const Users: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [ids, setIds] = useState<string[]>([])
  const [file, setFile] = useState<File | null>(null)
  const { loading, data, error, refetch } = useQuery(GET_PARTICIPANTS, {
    fetchPolicy: 'network-only'
  })
  const [deleteParticipant] = useMutation(DELETE_PARTICIPANT)
  const [bulkUpdateParticipant] = useMutation(STATUS_PARTICIPANT)
  const [importParticipants] = useMutation(IMPORT_PARTICIPANT)

  const history = useHistory()

  useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  const handleDelete = (ids: string[]) => {
    setIds(ids)
    handleClickOpen()
  }

  const handleStatusChange = (
    action: ParticipantBulkActionEnum,
    ids: string[]
  ) => {
    bulkUpdateParticipant({
      refetchQueries: ['Participants'],
      variables: {
        input: {
          ids,
          all: false,
          action
        }
      }
    })
  }

  const confirmDelete = () => {
    deleteParticipant({
      refetchQueries: ['Participants'],
      variables: {
        ids
      }
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (error) {
    return <h1>{error.message}</h1>
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFileInput = (e: any) => {
    const { target } = e

    const file = target.files[0]

    console.log(file)

    setFile(file)
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Participantes
      </Typography>
      <br />
      <Grid container spacing={3} justify="space-between">
        <Grid item xs={12} sm={6} md={3}>
          <CardDashboard title="Importar participantes">
            <label htmlFor="file">
              <div style={{ cursor: 'pointer' }}>
                <input
                  type="file"
                  id="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={e => handleFileInput(e)}
                  style={{ display: 'none' }}
                />
                <GetAppIcon />
                {file && <br />}
                {file && file.name}
                {file && (
                  <Button
                    onClick={() => {
                      importParticipants({
                        refetchQueries: ['Participants'],
                        variables: {
                          file
                        }
                      }).then(res => {
                        setFile(null)
                        toast.success(res.data.importParticipants.message)
                      })
                    }}
                    color="primary"
                    variant="contained"
                    autoFocus
                  >
                    Confirmar
                  </Button>
                )}
                <br />
              </div>
            </label>
          </CardDashboard>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" color="textPrimary" component="h2">
            Lista de participantes
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!loading ? (
            <UsersTableList
              loading={loading}
              handleClickDelete={handleDelete}
              handleClickStatus={handleStatusChange}
              handleClickEdit={id => history.push(`/admin/usuarios/${id}/edit`)}
              data={data}
            />
          ) : (
            <h1>Carregando...</h1>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => history.push('/admin/usuarios/new')}
            color="primary"
          >
            Criar Participante
          </Button>
        </Grid>
      </Grid>
      {/* Delete dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Deletar participante ?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja deletar o participante ? Esta ação não pode
            ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleClose()
              confirmDelete()
            }}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Users
