import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import {
  ConfigurationScreenOptionsInput,
  ConfigurationScreenTypeEnum
} from '../../components/configuration/configuration-types'
import { GET_CATEGORIES } from '../../components/GraphQl/categories'
import {
  GET_CONFIGURATION,
  SUBSCRIPTION_CONFIG
} from '../../components/GraphQl/configurations'
import {
  CLOSE_VOTING,
  OPEN_VOTING,
  SET_SCREEN,
  START_RESULT,
  STOP_RESULT
} from '../../components/GraphQl/vote'
import VoteCategoryNavigation from '../../components/vote/category-navigation'
import PreviewCard from '../../components/vote/preview-card'

const Vote: React.FC = () => {
  const { loading, data, error, refetch } = useQuery(GET_CATEGORIES)
  const {
    loading: loadingConfiguration,
    data: dataConfiguration,
    error: errorConfiguration,
    subscribeToMore
  } = useQuery(GET_CONFIGURATION)
  const [openVoting, { loading: loadingOpenVoting }] = useMutation(OPEN_VOTING)
  const [closeVoting, { loading: loadingCloseVoting }] = useMutation(
    CLOSE_VOTING
  )
  const [setScreen, { loading: loadingScreenConfig }] = useMutation(SET_SCREEN)
  const [startResult, { loading: loadingStartResult }] = useMutation(
    START_RESULT
  )
  const [stopResult, { loading: loadingStopResult }] = useMutation(STOP_RESULT)

  useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIPTION_CONFIG,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev
        return Object.assign({
          configurationMap: subscriptionData.data.configurationMapOnChanges.data
        })
      },
      onError: error => {
        toast.error(error.message)
      }
    })
  }, [subscribeToMore])

  const handleOpentVoting = (category: string) => {
    openVoting({
      variables: {
        category
      }
    })
  }

  const handleCloseVoting = () => {
    closeVoting()
  }

  const handleSetVotingScreen = (
    screen: ConfigurationScreenTypeEnum,
    options?: ConfigurationScreenOptionsInput
  ) => {
    setScreen({
      variables: {
        screen,
        options
      }
    })
  }

  return (
    <div>
      {loading && loadingConfiguration && <h1>Carregando...</h1>}
      {error && <h1>{error.message}</h1>}
      {errorConfiguration && <h1>{errorConfiguration.message}</h1>}
      {data && dataConfiguration && (
        <>
          <VoteCategoryNavigation
            data={data}
            loading={
              loadingOpenVoting ||
              loading ||
              loadingConfiguration ||
              loadingCloseVoting ||
              loadingScreenConfig ||
              loadingStartResult ||
              loadingStopResult
            }
            setScreen={handleSetVotingScreen}
            configurationMap={dataConfiguration.configurationMap}
            openVoting={handleOpentVoting}
            closeVoting={handleCloseVoting}
            startAutoResult={startResult}
            stopAutoResult={stopResult}
          />
          <PreviewCard configurationMap={dataConfiguration.configurationMap} />
          <br />
        </>
      )}
    </div>
  )
}

export default Vote
