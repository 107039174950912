import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import UsersTableList from '../../components/Users/table'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  DELETE_PARTICIPANT,
  GET_PARTICIPANTS,
  STATUS_PARTICIPANT
} from '../../components/GraphQl/participants'
import { useMutation, useQuery } from '@apollo/client'
import { ParticipantBulkActionEnum } from '../../components/configuration/configuration-types'

interface MatchParams {
  status: string
}

type WaitlistProps = RouteComponentProps<MatchParams>

const Waitlist: React.FC<WaitlistProps> = ({ match }) => {
  const { status } = match.params
  const [open, setOpen] = useState<boolean>(false)
  const [ids, setIds] = useState<string[]>([])
  const { loading, data, error, refetch } = useQuery(GET_PARTICIPANTS, {
    variables: {
      params: {
        filter: {
          status
        }
      }
    },
    fetchPolicy: 'network-only'
  })
  const [deleteParticipant] = useMutation(DELETE_PARTICIPANT)
  const [bulkUpdateParticipant] = useMutation(STATUS_PARTICIPANT)

  useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  const history = useHistory()

  const handleDelete = (ids: string[]) => {
    setIds(ids)
    handleClickOpen()
  }

  const handleStatusChange = (
    action: ParticipantBulkActionEnum,
    ids: string[]
  ) => {
    bulkUpdateParticipant({
      refetchQueries: ['Participants'],
      variables: {
        input: {
          ids,
          all: false,
          action
        }
      }
    })
  }

  const confirmDelete = () => {
    deleteParticipant({
      refetchQueries: ['Participants'],
      variables: {
        ids
      }
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (error) {
    return <h1>{error.message}</h1>
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3" color="textPrimary" component="h2">
            Participantes na sala de espera.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {!loading ? (
            <UsersTableList
              loading={loading}
              handleClickDelete={handleDelete}
              handleClickStatus={handleStatusChange}
              handleClickEdit={id => history.push(`/admin/usuarios/${id}/edit`)}
              data={data}
            />
          ) : (
            <h1>Carregando...</h1>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => history.push('/admin/usuarios/new')}
            color="primary"
          >
            Criar Participante
          </Button>
        </Grid>
      </Grid>
      {/* Delete dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Deletar participante ?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja deletar o participante ? Esta ação não pode
            ser desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleClose()
              confirmDelete()
            }}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Waitlist
