import { gql } from '@apollo/client'

export const GET_PARTICIPANTS = gql`
  query Participants($params: ParticipantSearchInput) {
    participants(params: $params) {
      edges {
        _id
        name
        role
        status
        login
        keypad
      }
      pagination {
        total
        page
      }
    }
  }
`
export const GET_PARTICPANT = gql`
  query Participant($id: ID!) {
    participant(id: $id) {
      _id
      name
      role
      status
      login
      keypad
    }
  }
`

export const ADD_PARTICIPANT = gql`
  mutation CreateParticipant($input: ParticipantCreateInput!) {
    createParticipant(input: $input) {
      message
      data {
        _id
        name
        role
        status
        login
        keypad
      }
    }
  }
`

export const UPDATE_PARTICIPANT = gql`
  mutation UpdateParticipant($id: ID!, $inputUpdate: ParticipantUpdateInput!) {
    updateParticipant(id: $id, input: $inputUpdate) {
      message
      data {
        _id
        name
        role
        status
        login
        keypad
      }
    }
  }
`
export const DELETE_PARTICIPANT = gql`
  mutation DeleteParticipants($ids: [ID!]!) {
    deleteParticipant(ids: $ids) {
      message
    }
  }
`
export const STATUS_PARTICIPANT = gql`
  mutation BulkParticipant($input: BulkUpdateParticipantInput!) {
    bulkUpdateParticipant(input: $input) {
      message
    }
  }
`

export const WAIT_LIST = gql`
  query Room {
    waitingRoom {
      PENDING
      APPROVED
      BLOCKED
    }
  }
`

export const WAIT_LIST_ON_CHANGE = gql`
  subscription RoomOnChange {
    waitingRoomOnChange {
      message
      status {
        PENDING
        APPROVED
        BLOCKED
      }
    }
  }
`
export const IMPORT_PARTICIPANT = gql`
  mutation ImportParticipants($file: Upload!) {
    importParticipants(file: $file) {
      message
    }
  }
`
export const SET_PARTICIPANT_AV = gql`
  mutation SetAvParticipant($id: ID!, $av: ParticipantAvEnum!) {
    setAvParticipant(id: $id, av: $av) {
      message
      data {
        _id
        name
        av
      }
    }
  }
`
