import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
  CategoryStatusEnum,
  ConfigurationMap,
  ConfigurationScreenTypeEnum
} from '../configuration/configuration-types'

const useStyles = makeStyles({
  root: {
    width: '100%',
    textAlign: 'center'
  },
  title: {
    fontSize: 14
  }
})

interface PreviewCardProps {
  configurationMap: ConfigurationMap
}

const PreviewCard: React.FC<PreviewCardProps> = ({ configurationMap }) => {
  const classes = useStyles()

  const handleTitle = () => {
    switch (configurationMap.screen?.screenType) {
      case ConfigurationScreenTypeEnum.Empty:
        return 'Vazio'
      case ConfigurationScreenTypeEnum.Cover:
        return 'Capa'
      case ConfigurationScreenTypeEnum.Message:
        return 'Mensagem'
      case ConfigurationScreenTypeEnum.Result:
        return 'Resultado'
      case ConfigurationScreenTypeEnum.Voting:
        return 'Votação'
    }
  }

  const handleContent = () => {
    switch (configurationMap.screen?.screenType) {
      case ConfigurationScreenTypeEnum.Empty:
        if (configurationMap.appearance?.assets?.logo) {
          return (
            <>
              <Typography variant="h5" component="h2">
                Exibindo a tela de logo
              </Typography>
              <img
                src={configurationMap.appearance?.assets?.logo}
                alt="Logo Prêmio Innovare"
                width="500px"
              />
            </>
          )
        } else {
          return (
            <Typography variant="h5" component="h2">
              Exibindo uma tela vazia
            </Typography>
          )
        }
      case ConfigurationScreenTypeEnum.Cover:
        return (
          <>
            <Typography variant="h5" component="h2">
              Tela de capa RESULTADO da categoria:
            </Typography>
            <Typography variant="h5" component="h2">
              {configurationMap.screen.category?.name}
            </Typography>
          </>
        )
      case ConfigurationScreenTypeEnum.Message:
        return (
          <>
            <Typography variant="h4" component="h2">
              {configurationMap.messageCurrent?.title}
            </Typography>
            {configurationMap.messageCurrent?.content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: configurationMap.messageCurrent?.content
                }}
              ></div>
            )}
          </>
        )
      case ConfigurationScreenTypeEnum.Result:
        return (
          <>
            <Typography variant="h5" component="h2">
              Tela de capa RESULTADO exibição das praticas ganhadoras:
            </Typography>
            <Typography variant="h5" component="h2">
              {configurationMap.screen.category?.name}
            </Typography>
            {configurationMap.screen?.results?.map(practice => (
              <Typography variant="h6" component="h6" key={practice?._id}>
                {practice?.number} - {practice?.name}
              </Typography>
            ))}
          </>
        )
      case ConfigurationScreenTypeEnum.Voting:
        if (
          configurationMap.screen?.category?.status ===
          CategoryStatusEnum.Voting
        ) {
          return (
            <>
              <Typography variant="h5" component="h2">
                {`A votação foi iniciada, categoria: ${configurationMap.screen.category?.name}`}
              </Typography>
              {configurationMap.screen?.category?.practices.map(practice => (
                <Typography variant="h6" component="h6" key={practice?._id}>
                  {practice?.number} - {practice?.name}
                </Typography>
              ))}
            </>
          )
        } else {
          return (
            <>
              <Typography variant="h5" component="h2">
                {`A votação esta parada, categoria selecionada é: ${configurationMap.screen.category?.name}`}
              </Typography>
              {configurationMap.screen?.category?.practices.map(practice => (
                <Typography variant="h6" component="h6" key={practice?._id}>
                  {practice?.number} - {practice?.name}
                </Typography>
              ))}
            </>
          )
        }
    }
  }

  return (
    <>
      <br />
      <Typography variant="h5">
        Informações sobre a tela atual do participante
      </Typography>
      <br />
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Tela atual:
          </Typography>
          <Typography variant="h5" component="h2">
            {handleTitle()}
          </Typography>
          {handleContent()}
        </CardContent>
      </Card>
    </>
  )
}

export default PreviewCard
