import Categories from '../../pages/Categories'
import CategoryEdit from '../../pages/Categories/edit-category'
import CategoryNew from '../../pages/Categories/new-category'
import Dashboard from '../../pages/Dashboard'
import Practices from '../../pages/Practices'
import Users from '../../pages/Users'
import PracticeNew from '../../pages/Practices/new-practice'
import PracticeEdit from '../../pages/Practices/edit-practice'
import AwardNew from '../../pages/Awards/new-award'
import AwardEdit from '../../pages/Awards/edit-awards'
import Awards from '../../pages/Awards'
import Messages from '../../pages/Messages'
import MessageNew from '../../pages/Messages/new-message'
import MessageEdit from '../../pages/Messages/edit-message'
import ParticipantNew from '../../pages/Users/new-user'
import ParticipantEdit from '../../pages/Users/edit-user'
import Configuration from '../../pages/Configuration'
import Vote from '../../pages/Vote'
import Waitlist from '../../pages/Waitlist'

export const pages = [
  {
    title: 'Início',
    icon: 'fas fa-home',
    link: '/admin',
    component: Dashboard,
    menu: true
  },
  {
    title: 'Participantes',
    icon: 'fas fa-users',
    link: '/admin/usuarios',
    component: Users,
    menu: true
  },
  {
    title: 'Criar Participante',
    icon: 'fas fa-user',
    link: '/admin/usuarios/new',
    component: ParticipantNew
  },
  {
    title: 'Editar Participante',
    icon: 'fas fa-user',
    link: '/admin/usuarios/:id/edit',
    component: ParticipantEdit
  },
  {
    title: 'Votantes',
    icon: 'fas fa-person-booth'
  },
  {
    title: 'Categorias',
    icon: 'fas fa-tag',
    link: '/admin/categorias',
    component: Categories,
    menu: true
  },
  {
    title: 'Nova Categoria',
    icon: 'fas fa-tag',
    link: '/admin/categorias/new',
    component: CategoryNew
  },
  {
    title: 'Edit Categoria',
    icon: 'fas fa-tag',
    link: '/admin/categorias/:id/edit',
    component: CategoryEdit
  },
  {
    title: 'Praticas',
    icon: 'fas fa-clipboard-list',
    link: '/admin/praticas',
    component: Practices,
    menu: true
  },
  {
    title: 'Nova Pratica',
    icon: 'fas fa-tags',
    link: '/admin/praticas/new',
    component: PracticeNew
  },
  {
    title: 'Editar Pratica',
    icon: 'fas fa-tags',
    link: '/admin/praticas/:id/edit',
    component: PracticeEdit
  },
  {
    title: 'Prêmios',
    icon: 'fas fa-award',
    link: '/admin/premios',
    component: Awards,
    menu: true
  },
  {
    title: 'Novo prêmio',
    icon: 'fas fa-award',
    link: '/admin/premios/new',
    component: AwardNew
  },
  {
    title: 'Editar prêmio',
    icon: 'fas fa-award',
    link: '/admin/premios/:id/edit',
    component: AwardEdit
  },
  {
    title: 'Mensagens',
    icon: 'fas fa-envelope',
    link: '/admin/mensagens',
    component: Messages,
    menu: true
  },
  {
    title: 'Nova Mensagem',
    icon: 'fas fa-envelope',
    link: '/admin/mensagens/new',
    component: MessageNew
  },
  {
    title: 'Editar Mensagem',
    icon: 'fas fa-envelope',
    link: '/admin/mensagens/:id/edit',
    component: MessageEdit
  },
  {
    title: 'Configurações',
    icon: 'fas fa-cog',
    link: '/admin/config',
    component: Configuration,
    menu: true
  },
  {
    title: 'Votação',
    icon: 'fas fa-vote-yea',
    link: '/admin/votacao',
    component: Vote,
    menu: true
  },
  {
    title: 'Sala de espera',
    icon: 'fas fa-user-clock',
    link: '/admin/waitlist/:status',
    component: Waitlist
  }
]
