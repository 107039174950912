import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'

interface IProps {
  exact?: boolean
  path: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>
}

export const PrivateRoute: React.FC<IProps> = ({
  component: Component,
  exact,
  ...rest
}) => {
  const [keycloak] = useKeycloak()

  return (
    <Route
      {...rest}
      exact={exact}
      render={props =>
        keycloak.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}
