import { useKeycloak } from '@react-keycloak/web'
import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import { pages } from '../components/Layout/pages'
import { useUser } from './userValidation'

const Private: React.FC = () => {
  const [keycloak] = useKeycloak()

  const user = useUser()

  const history = useHistory()

  if (user && user.panelMe) {
    if (user.panelMe.roles[0] === undefined || user.panelMe.roles[0] === null) {
      history.push('/blocked')
      setTimeout(() => {
        keycloak.logout()
      }, 5000)
      return <></>
    }
  }

  return (
    <Layout>
      {pages.map(page => (
        <Route
          path={page.link}
          exact
          component={page.component}
          key={page.title}
        />
      ))}
    </Layout>
  )
}

export default Private
