import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Participant } from '../configuration/configuration-types'
import IconConnection from './IconConnection'
import IconAV from './IconAv'

const useStyles = makeStyles({
  root: {
    width: 120,
    minHeight: 60,
    maxHeight: 60,
    position: 'relative'
  },
  content: {
    padding: '0px!important',
    backgroundColor: '#fff',
    height: '60px'
  },
  contentTop: {
    padding: '3px',
    height: '40px',
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  },
  name: {
    fontSize: 'calc(0.75em + 1vmin)',
    marginBottom: 5,
    height: '40px',
    overflow: 'hidden',
    fontWeight: 'bold'
  },
  contentMiddle: {
    height: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 4px'
  }
})
interface CardProps {
  participant: Participant
}

export const ParticipantNoVoterCard: React.FC<CardProps> = ({
  participant
}) => {
  const classes = useStyles()

  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.contentTop}>
          <span
            className={classes.name}
            style={{ fontSize: participant.name.length > 30 ? '11px' : '14px' }}
          >
            {participant.name}
          </span>
        </div>
        <div className={classes.contentMiddle}>
          <IconConnection connection={participant.connection} />
          <IconAV participant={participant} />
        </div>
      </CardContent>
    </Card>
  )
}

export default ParticipantNoVoterCard
