import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import AwardForm from '../../components/awards/form-awards'
import { AwardData } from '../../components/awards/award-types'
import { GET_AWARD, UPDATE_AWARD } from '../../components/GraphQl/awards'
import { Category } from '../../components/Categories/category-types'

interface MatchParams {
  id: string
}

type AwardEditProps = RouteComponentProps<MatchParams>

const AwardEdit: React.FC<AwardEditProps> = ({ match }) => {
  const [updateAward, { loading: loadingUpdate }] = useMutation(UPDATE_AWARD)
  const { id } = match.params

  const { data, loading, error } = useQuery(GET_AWARD, {
    variables: {
      id
    }
  })

  const history = useHistory()

  const [award, setAward] = useState<AwardData>({
    name: '',
    weight: 0,
    categories: []
  })

  useEffect(() => {
    if (data && data.award) {
      setAward(data.award)
    }
  }, [data])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const categories = award.categories.map(categorie => categorie._id)

    updateAward({
      variables: {
        id,
        inputUpdate: {
          name: award.name,
          weight: award.weight,
          categories: categories
        }
      }
    }).then(() => {
      history.push('/admin/premios')
    })
  }

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    if (name === 'weight') {
      return setAward(practice => ({ ...practice, [name]: Number(value) }))
    }
    setAward(practice => ({ ...practice, [name]: value }))
  }

  const handleCategoryChange = (categoryId: Category[]) => {
    setAward(practice => ({ ...practice, categories: categoryId }))
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Prêmio {(data && data.award && data.award.name) || ''}
      </Typography>
      <Grid item xs={12}>
        {error && <h1>{error.message}</h1>}
        {loading ? (
          <h1>Carregando...</h1>
        ) : (
          <AwardForm
            loading={loadingUpdate}
            onSubmit={event => handleSubmit(event)}
            onChange={handleChange}
            categoryChange={handleCategoryChange}
            award={data.award}
          />
        )}
      </Grid>
    </>
  )
}

export default AwardEdit
