import React from 'react'
import Keycloak, { KeycloakError } from 'keycloak-js'
import { KeycloakProvider, KeycloakEvent } from '@react-keycloak/web'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import { AppRouter } from './routes'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'

const kcPath = process.env.REACT_APP_KEYCLOAK_JSON || '/keycloak.json'
const keycloak = Keycloak(kcPath)

enum KeycloakOnLoad {
  required = 'login-required',
  sso = 'check-sso'
}

const keycloakProviderInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: KeycloakOnLoad.sso
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#c84347',
      main: '#bb141a',
      dark: '#820e12',
      contrastText: '#fff'
    },
    secondary: {
      light: '#f0494f',
      main: '#ED1C24',
      dark: '#a51319',
      contrastText: '#fff'
    }
  }
})

const App: React.FC = () => {
  const onKeycloakEvent = (
    event: KeycloakEvent,
    error?: KeycloakError | undefined
  ) => {
    console.log('onKeycloakEvent', event, error)
  }

  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={keycloakProviderInitConfig}
      onEvent={onKeycloakEvent}
    >
      <ThemeProvider theme={theme}>
        <AppRouter />
        <ToastContainer />
      </ThemeProvider>
    </KeycloakProvider>
  )
}

export default App
