import React, { useEffect } from 'react'
import {
  Participant,
  ParticipantAVEnum
} from '../configuration/configuration-types'
import { makeStyles } from '@material-ui/core/styles'
import { Menu, MenuItem } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { SET_PARTICIPANT_AV } from '../GraphQl/participants'

const useStyles = makeStyles({
  iconBox: {
    background: '#fff',
    padding: '4px',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    lineHeight: '15px',
    textAlign: 'center',
    fontSize: '10px',
    cursor: 'pointer'
  }
})
interface IopType {
  label: string
  key: ParticipantAVEnum
}

const options: IopType[] = [
  { label: ' Mudar AV para:', key: ParticipantAVEnum.Ignored },
  { label: 'Remoto', key: ParticipantAVEnum.On },
  { label: 'Presencial', key: ParticipantAVEnum.Off },
  { label: 'AV: Pendente', key: ParticipantAVEnum.Pending }
]
type Props = {
  participant: Participant
}
const IconAV: React.FC<Props> = ({ participant }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const [updateParticipant] = useMutation(SET_PARTICIPANT_AV)

  useEffect(() => {
    if (participant) {
      if (participant.av === ParticipantAVEnum.On) {
        setSelectedIndex(1)
      } else if (participant.av === ParticipantAVEnum.Off) {
        setSelectedIndex(2)
      } else {
        setSelectedIndex(0)
      }
    }
  }, [participant])

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number,
    option: ParticipantAVEnum
  ) => {
    updateParticipant({
      variables: {
        id: participant._id,
        av: option
      }
    }).then(() => {
      setSelectedIndex(index)
      setAnchorEl(null)
    })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleIconAv = () => {
    switch (participant.av) {
      case ParticipantAVEnum.On:
        return <i className="fas fa-columns" style={{ color: '#0b821a' }} />
      case ParticipantAVEnum.Off:
        return <i className="fas fa-desktop" style={{ color: '#f7052e' }} />
      case ParticipantAVEnum.Pending:
        return (
          <i className="fas fa-hourglass-half" style={{ color: '#F57F02FF' }} />
        )
      case ParticipantAVEnum.Ignored:
      default:
        return ''
    }
  }

  if (participant.av !== ParticipantAVEnum.Ignored) {
    return (
      <>
        <div
          className={classes.iconBox}
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleClickListItem}
          aria-label="when device is locked"
        >
          {handleIconAv()}
        </div>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option.key}
              disabled={index === 0}
              selected={index === selectedIndex}
              onClick={event => handleMenuItemClick(event, index, option.key)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  }

  return <span></span>
}
export default IconAV
