import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AwardsTableList from '../../components/awards/table-awards'
import { DELETE_AWARD, GET_AWARDS } from '../../components/GraphQl/awards'

const Awards: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [ids, setIds] = useState<string[]>([])
  const { loading, data, error, refetch } = useQuery(GET_AWARDS, {
    fetchPolicy: 'network-only'
  })
  const [deleteAward, { loading: deleteLoading }] = useMutation(DELETE_AWARD)

  const history = useHistory()

  useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  if (error) {
    return <h1>{error.message}</h1>
  }

  const handleDelete = (ids: string[]) => {
    setIds(ids)
    handleClickOpen()
  }

  const confirmDelete = () => {
    deleteAward({
      refetchQueries: () => ['Awards'],
      variables: {
        ids
      }
    })
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Prêmios
      </Typography>
      <Grid item xs={12}>
        {loading ? (
          <h1>Carregando...</h1>
        ) : (
          <AwardsTableList
            loading={loading || deleteLoading}
            data={data}
            handleClickEdit={id => history.push(`/admin/premios/${id}/edit`)}
            handleClickDelete={ids => handleDelete(ids)}
          />
        )}
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push('/admin/premios/new')}
        >
          Novo prêmio
        </Button>
      </Grid>
      {/* Delete dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Deletar prêmio ?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja deletar prêmio ? Esta ação não pode ser
            desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleClose()
              confirmDelete()
            }}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Awards
