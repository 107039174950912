import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DELETE_MESSAGE, GET_MESSAGES } from '../../components/GraphQl/messages'
import MessageTableList from '../../components/messages/table-messages'

const Messages: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [ids, setIds] = useState<string[]>([])
  const { loading, data, error, refetch } = useQuery(GET_MESSAGES, {
    fetchPolicy: 'network-only'
  })
  const [deleteMessage] = useMutation(DELETE_MESSAGE)

  const history = useHistory()

  useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  if (error) {
    return <h1>{error.message}</h1>
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const confirmDelete = () =>
    deleteMessage({
      refetchQueries: ['Messages'],
      variables: {
        ids
      }
    }).then(() => {
      setIds([])
    })

  const handleDelete = (ids: string[]) => {
    setIds(ids)
    handleClickOpen()
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Mensagens
      </Typography>
      <Grid item xs={12}>
        {loading ? (
          <h1>Carregando...</h1>
        ) : (
          <MessageTableList
            loading={loading}
            data={data}
            handleClickEdit={id => history.push(`/admin/mensagens/${id}/edit`)}
            handleClickDelete={ids => handleDelete(ids)}
          />
        )}
      </Grid>
      <Grid item>
        <Button
          color="primary"
          variant="contained"
          onClick={() => history.push('/admin/mensagens/new')}
        >
          Nova Mensagem
        </Button>
      </Grid>
      {/* Delete dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Deletar mensagem ?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tem certeza que deseja deletar a mensagem ? Esta ação não pode ser
            desfeita.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleClose()
              confirmDelete()
            }}
            color="primary"
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Messages
