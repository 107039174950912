import { useMutation, useQuery } from '@apollo/client'
import { Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import {
  GET_CONFIGURATION,
  CONFIGURATION_APPEARANCE,
  CONFIGURATION_COMMUNICATION,
  CONFIGURATION_REGISTER,
  SUBSCRIPTION_CONFIG
} from '../../components/GraphQl/configurations'
import {
  ConfigurationAppearanceStyleColors,
  ConfigurationAppearanceStyleFont,
  ConfigurationSignUpAuthTypeEnum
} from '../../components/configuration/configuration-types'
import AppearanceConfig from '../../components/configuration/appearence-config'
import AppearanceUpload from '../../components/configuration/appearance-upload'
import CommunicationConfiguration from '../../components/configuration/communication-config'
import RegisterConfiguration from '../../components/configuration/register-config'
import { toast } from 'react-toastify'
import { CommunicationEnum } from '../../schema'

enum OptionEnum {
  GLOBAL = 'GLOBAL',
  PRACTICE = 'PRACTICE',
  CATEGORY = 'CATEGORY'
}
const Configuration: React.FC = () => {
  const { loading, data, error, subscribeToMore } = useQuery(GET_CONFIGURATION)
  const [setConfigAppearance, { loading: configLoading }] = useMutation(
    CONFIGURATION_APPEARANCE
  )
  const [SetConfStreaming, { loading: streamingLoading }] = useMutation(
    CONFIGURATION_COMMUNICATION
  )
  const [setConfigSignUp, { loading: signUpLoading }] = useMutation(
    CONFIGURATION_REGISTER
  )

  useEffect(() => {
    subscribeToMore({
      document: SUBSCRIPTION_CONFIG,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) return prev
        return Object.assign({
          configurationMap: subscriptionData.data.configurationMapOnChanges.data
        })
      },
      onError: error => {
        toast.error(error.message)
      }
    })
  }, [subscribeToMore])

  if (error) {
    return <h1>{error.message}</h1>
  }

  if (loading) {
    return <h1>Carregando...</h1>
  }

  const configSignupConfirm = (
    currentConfig: ConfigurationSignUpAuthTypeEnum
  ) => {
    setConfigSignUp({
      variables: {
        authType: currentConfig
      }
    })
  }

  const appearanceConfirm = (
    option: OptionEnum,
    font: ConfigurationAppearanceStyleFont,
    colors: ConfigurationAppearanceStyleColors
  ) => {
    switch (option) {
      case OptionEnum.CATEGORY:
        return setConfigAppearance({
          variables: {
            categories: { font, colors }
          }
        })
      case OptionEnum.PRACTICE:
        return setConfigAppearance({
          variables: {
            practices: { font, colors }
          }
        })
      case OptionEnum.GLOBAL:
        return setConfigAppearance({
          variables: {
            global: { font, colors }
          }
        })
    }
  }

  const appearanceUploadConfirm = (backgroundImage: File, logo: File) => {
    if (backgroundImage === null) {
      setConfigAppearance({
        variables: {
          assets: { logo: logo }
        }
      })
    } else if (logo === null) {
      setConfigAppearance({
        variables: {
          assets: { backgroundImage: backgroundImage }
        }
      })
    } else {
      setConfigAppearance({
        variables: {
          assets: { backgroundImage: backgroundImage, logo: logo }
        }
      })
    }
  }

  const handleDeleteBackground = (backgroundImage: null) => {
    setConfigAppearance({
      variables: {
        assets: { backgroundImage: backgroundImage }
      }
    })
  }

  const handleDeleteLogo = (logo: null) => {
    setConfigAppearance({
      variables: {
        assets: { logo: logo }
      }
    })
  }
  const handleConfirmStreaming = (option: CommunicationEnum, value: string) => {
    switch (option) {
      case CommunicationEnum.IFRAME:
        return SetConfStreaming({
          variables: {
            input: {
              enable: true,
              iframe: value,
              embed: ''
            }
          }
        })
      case CommunicationEnum.EMBED:
      case CommunicationEnum.ZOOM:
        return SetConfStreaming({
          variables: {
            input: {
              enable: true,
              embed: value,
              iframe: ''
            }
          }
        })
      case CommunicationEnum.DISABLED:
        return SetConfStreaming({
          variables: {
            input: {
              enable: false
            }
          }
        })
    }
  }

  return (
    <div
      style={{
        height: '60vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
      }}
    >
      <Typography variant="h3" color="textPrimary" component="h1">
        Configurações Gerais
      </Typography>
      <br />
      {/* Messages section */}
      <Grid container justify="space-between" spacing={2}>
        <RegisterConfiguration
          data={data}
          handleConfirm={configSignupConfirm}
          loading={signUpLoading}
        />
      </Grid>
      <br />
      <Typography variant="h3" color="textPrimary" component="h1">
        Configurações de aparência
      </Typography>
      <br />
      <Grid container justify="space-between" spacing={2}>
        <AppearanceConfig
          data={data}
          handleConfirm={appearanceConfirm}
          option={OptionEnum.GLOBAL}
        />
        <AppearanceConfig
          data={data}
          handleConfirm={appearanceConfirm}
          option={OptionEnum.PRACTICE}
        />
        <AppearanceConfig
          data={data}
          handleConfirm={appearanceConfirm}
          option={OptionEnum.CATEGORY}
        />
        <AppearanceUpload
          data={data}
          loading={configLoading}
          handleConfirm={appearanceUploadConfirm}
          handleDeleteBackground={handleDeleteBackground}
          handleDeleteLogo={handleDeleteLogo}
        />
        <CommunicationConfiguration
          data={data}
          handleConfirm={handleConfirmStreaming}
          loading={streamingLoading}
        />
      </Grid>
    </div>
  )
}

export default Configuration
