import { gql } from '@apollo/client'

export const GET_AWARDS = gql`
  query Awards($paramsAwards: AwardSearchInput) {
    awards(params: $paramsAwards) {
      edges {
        _id
        name
        weight
        categories {
          _id
          name
          number
          quantityWin
        }
      }
      pagination {
        total
        perPage
        page
        lastPage
      }
    }
  }
`

export const GET_AWARD = gql`
  query Award($id: ID!) {
    award(id: $id) {
      _id
      name
      weight
      categories {
        _id
        name
        number
        quantityWin
      }
    }
  }
`

export const ADD_AWARD = gql`
  mutation CreateAward($input: AwardCreateInput!) {
    createAward(input: $input) {
      message
      data {
        _id
        name
        weight
        categories {
          _id
          name
          number
          quantityWin
        }
      }
    }
  }
`

export const UPDATE_AWARD = gql`
  mutation UpdateAward($id: ID!, $inputUpdate: AwardUpdateInput!) {
    updateAward(id: $id, input: $inputUpdate) {
      message
      data {
        _id
        name
        weight
        categories {
          _id
          name
          number
          quantityWin
        }
      }
    }
  }
`

export const DELETE_AWARD = gql`
  mutation DeletAward($ids: [ID!]!) {
    deleteAward(ids: $ids) {
      message
    }
  }
`
