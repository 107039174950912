import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Participant } from '../configuration/configuration-types'
import IconConnection from './IconConnection'
import IconAV from './IconAv'

const useStyles = makeStyles({
  root: {
    width: 120,
    minHeight: 100,
    maxHeight: 120,
    position: 'relative'
  },
  content: {
    padding: '0px!important',
    backgroundColor: '#fff',
    height: '100px'
  },
  contentTop: {
    padding: '3px',
    height: '50%',
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  },
  name: {
    fontSize: 'calc(0.75em + 1vmin)',
    marginBottom: 5,
    height: '40px',
    overflow: 'hidden',
    fontWeight: 'bold'
  },
  contentMiddle: {
    height: '20px',
    marginBottom: '-10px',
    marginTop: '-10px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 4px'
  },
  contentBottom: {
    padding: '3px',
    borderTop: '1px solid #ccc',
    height: '50%'
  }
})
interface CardProps {
  participant: Participant
}

export const ParticipantCard: React.FC<CardProps> = ({ participant }) => {
  const classes = useStyles()
  const getVotes = (part: Participant) => {
    const stringVotes = part.votedPractices
      ? part.votedPractices.join(', ')
      : ''
    if (!part.votedPractices || part.votedPractices.length === 0) {
      return <span>Não votou</span>
    } else {
      return (
        <span style={{ fontSize: stringVotes.length > 30 ? '11px' : '14px' }}>
          {stringVotes}
        </span>
      )
    }
  }

  return (
    <Card variant="outlined" className={classes.root}>
      <CardContent className={classes.content}>
        <div
          className={classes.contentTop}
          style={{
            backgroundColor:
              participant.votedPractices === null ||
              participant.votedPractices.length === 0
                ? 'rgba(0, 0, 0, 0.12)'
                : '#1d3959',
            color:
              participant.votedPractices === null ||
              participant.votedPractices.length === 0
                ? 'black'
                : 'white'
          }}
        >
          <span
            className={classes.name}
            style={{ fontSize: participant.name.length > 30 ? '11px' : '14px', lineHeight: participant.name.length > 30 ? '12px' : '14px' }}
          >
            {participant.name}
          </span>
        </div>
        <div className={classes.contentMiddle}>
          <IconConnection connection={participant.connection} />
          <IconAV participant={participant} />
        </div>
        <div className={classes.contentBottom}>{getVotes(participant)}</div>
      </CardContent>
    </Card>
  )
}

export default ParticipantCard
