import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import CategoryForm from '../../components/Categories/form-category'
import { CategoryData } from '../../components/Categories/category-types'
import { useMutation } from '@apollo/client'
import { ADD_CATEGORY } from '../../components/GraphQl/categories'
import { useHistory } from 'react-router-dom'

const CategoryNew: React.FC = () => {
  const [addCategory, { loading }] = useMutation(ADD_CATEGORY)

  const history = useHistory()

  const [category, setCategory] = useState<CategoryData>({
    name: '',
    number: 0,
    quantityWin: 0
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    addCategory({ variables: { input: category } }).then(() => {
      history.push('/admin/categorias')
    })
  }

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    if (name === 'number' || name === 'quantityWin') {
      return setCategory(category => ({ ...category, [name]: Number(value) }))
    }
    setCategory(category => ({ ...category, [name]: value }))
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Categorias
      </Typography>
      <Grid item xs={12}>
        <CategoryForm
          loading={loading}
          onSubmit={event => handleSubmit(event)}
          onChange={handleChange}
        />
      </Grid>
    </>
  )
}

export default CategoryNew
