import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    btnBack: {
      padding: '16px',
      width: '40%',
      margin: '0 10% 0 0'
    },
    btnSave: {
      padding: '16px',
      width: '40%',
      margin: '0 0 0 10%'
    }
  })
)

interface PartipantData {
  name: string
  role: string
  login: string
  keypad: string
  password?: string
}

interface FormProps {
  onSubmit(event: React.FormEvent<HTMLFormElement>): void
  onChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void
  data?: PartipantData
  edit?: boolean
  loading: boolean
}

const UserForm: React.FC<FormProps> = ({
  onSubmit,
  onChange,
  data,
  edit,
  loading
}) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <form
      className={classes.root}
      autoComplete="off"
      onSubmit={event => onSubmit(event)}
    >
      <TextField
        label="Nome do participante"
        variant="outlined"
        onChange={e => onChange(e)}
        name="name"
        defaultValue={data ? data.name : ''}
        required
      />
      <TextField
        label="Login do participante"
        variant="outlined"
        onChange={e => onChange(e)}
        name="login"
        defaultValue={data ? data.login : ''}
        required
      />
      {edit ? (
        <TextField
          label="Atualizar senha do participante"
          variant="outlined"
          onChange={e => onChange(e)}
          name="password"
        />
      ) : (
        <TextField
          label="Senha do participante"
          variant="outlined"
          onChange={e => onChange(e)}
          name="password"
          required
        />
      )}
      <TextField
        label="Keypad do participante"
        variant="outlined"
        onChange={e => onChange(e)}
        name="keypad"
        defaultValue={data ? data.keypad : ''}
        required
      />
      <FormLabel component="legend">Função</FormLabel>
      <RadioGroup
        aria-label="role"
        name="role"
        defaultValue={data ? data.role : 'VOTER'}
        onChange={e => onChange(e)}
      >
        <FormControlLabel value="VOTER" control={<Radio />} label="Votante" />
        <FormControlLabel
          value="VIEWER"
          control={<Radio />}
          label="Visitante"
        />
        <FormControlLabel
          value="GOD"
          control={<Radio />}
          label="Representante (pode votar por outros participantes)"
        />
      </RadioGroup>
      <Grid sm={12} justify="space-around" alignContent="space-around">
        <Button
          variant="outlined"
          color="primary"
          className={classes.btnBack}
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnSave}
          type="submit"
          disabled={loading}
        >
          Salvar
        </Button>
      </Grid>
    </form>
  )
}

export default UserForm
