import { useQuery } from '@apollo/client'
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  List,
  ListItem,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import SlideshowIcon from '@material-ui/icons/Slideshow'
import React from 'react'
import { Category, CategoryStatusEnum } from '../Categories/category-types'
import {
  ConfigurationScreen,
  ConfigurationScreenOptionsInput,
  ConfigurationScreenTypeEnum
} from '../configuration/configuration-types'
import { GET_RESULTS } from '../GraphQl/vote'
import { Practice } from '../Practices/practices-types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    }
  })
)

interface ClearVoteProps {
  loading: boolean
  category: Category
  setScreen(
    screen: ConfigurationScreenTypeEnum,
    options?: ConfigurationScreenOptionsInput
  ): void
  screen: ConfigurationScreen | undefined
}

const ResultPreview: React.FC<ClearVoteProps> = ({
  loading,
  category,
  setScreen,
  screen
}) => {
  const { loading: resultsLoading, data, refetch } = useQuery(GET_RESULTS, {
    variables: {
      category: category._id
    }
  })
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()

  const handleClickOpen = () => {
    refetch()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    setScreen(ConfigurationScreenTypeEnum.Result, {
      category: category._id
    })
    handleClose()
  }

  return (
    <>
      <Fab
        color="primary"
        size="large"
        aria-label="previa resultado"
        variant="extended"
        className={classes.extendedIcon}
        onClick={() => {
          handleClickOpen()
        }}
        disabled={
          loading ||
          resultsLoading ||
          (screen?.screenType === ConfigurationScreenTypeEnum.Result &&
            screen.category?._id === category._id)
        }
      >
        <SlideshowIcon />
        Prévia de Resultado
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Prévia do resultado</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veja a prévia do resultado antes de exibir para os participantes!
          </DialogContentText>
          <Typography variant="h4" component="h4">
            {data?.votesResult?.category?.name}
          </Typography>
          {data?.votesResult?.hasDraw && (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '12px',
              backgroundColor: '#f2dede', padding: '8px 4px', textAlign: 'center', textTransform: 'uppercase',
              fontWeight: 'bold', fontSize: '1.3rem', border: '1px solid #ebccd1', color: '#a94442', marginBottom: '8px'}}>
              <span>Empate</span>
            </div>
          )}

          <List>
            {data?.votesResult?.practices?.map((practice: Practice) => (
              <div key={practice._id}>
                <ListItem>
                  <Typography variant="h6" component="h6">
                    {practice.number} - {practice.name}
                  </Typography>
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            disabled={category.status !== CategoryStatusEnum.VOTED}
          >
            {category.status !== CategoryStatusEnum.VOTED
              ? 'AGUARDANDO VOTAÇÃO'
              : 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ResultPreview
