import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import CategoryForm from '../../components/Categories/form-category'
import { CategoryData } from '../../components/Categories/category-types'
import { useMutation, useQuery } from '@apollo/client'
import {
  GET_CATEGORY,
  UPDATE_CATEGORY
} from '../../components/GraphQl/categories'
import { RouteComponentProps, useHistory } from 'react-router-dom'

interface MatchParams {
  id: string
}

type CategoryEditProps = RouteComponentProps<MatchParams>

const CategoryEdit: React.FC<CategoryEditProps> = ({ match }) => {
  const [updateCategory, { loading: loadingUpdate }] = useMutation(
    UPDATE_CATEGORY
  )
  const { id } = match.params

  const { data, loading, error } = useQuery(GET_CATEGORY, {
    variables: {
      id
    }
  })

  const history = useHistory()

  const [category, setCategory] = useState<CategoryData>({
    name: '',
    number: 0,
    quantityWin: 0
  })

  useEffect(() => {
    if (data && data.category) {
      setCategory(data.category)
    }
  }, [data])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    updateCategory({
      variables: {
        id,
        inputUpdate: {
          name: category.name,
          number: category.number,
          quantityWin: category.quantityWin
        }
      }
    }).then(() => {
      history.push('/admin/categorias')
    })
  }

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    if (name === 'number' || name === 'quantityWin') {
      return setCategory(category => ({ ...category, [name]: Number(value) }))
    }
    setCategory(category => ({ ...category, [name]: value }))
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Categoria {(data && data.category && data.category.name) || ''}
      </Typography>
      <Grid item xs={12}>
        {error && <h1>{error.message}</h1>}
        {loading ? (
          <h1>Carregando...</h1>
        ) : (
          <CategoryForm
            loading={loadingUpdate}
            onSubmit={event => handleSubmit(event)}
            onChange={handleChange}
            data={data.category}
          />
        )}
      </Grid>
    </>
  )
}

export default CategoryEdit
