/* eslint-disable camelcase */
import { gql, useQuery } from '@apollo/client'
import React from 'react'

export enum PanelRoleEnum {
  ADMIN = 'ADMIN',
  SUPPORT = 'SUPPORT'
}

export type UserKeycloak = {
  name: string
  sub: string
  email_verified: boolean
  preferred_username: string
  given_name: string
  roles: PanelRoleEnum[]
}

export type Props = {
  panelMe?: UserKeycloak
  loading?: boolean
}

export type UserProps = {
  other?: string
} & Props

const PANEL_ME = gql`
  query AuthPanel {
    panelMe {
      sub
      name
      email_verified
      preferred_username
      roles
    }
  }
`

export const UserContext = React.createContext<UserProps>({})

export const UserProvider: React.FC = ({ children }) => {
  const { data, loading } = useQuery(PANEL_ME)

  return (
    <UserContext.Provider value={{ panelMe: data && data.panelMe, loading }}>
      {children}
    </UserContext.Provider>
  )
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUser = () => React.useContext(UserContext)
