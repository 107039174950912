import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  TextField
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import MessageIcon from '@material-ui/icons/Message'
import { Autocomplete } from '@material-ui/lab'
import {
  ConfigurationKeyEnum,
  ConfigurationMap,
  ConfigurationMessage
} from './configuration-types'
import { toast } from 'react-toastify'

interface DataMap {
  configurationMap: ConfigurationMap
}
interface MessageConfigurationProps {
  data: DataMap | undefined
  messagesData: {
    messages: {
      edges: ConfigurationMessage[]
    }
  }
  handleConfirm(currentMessage: ConfigurationMessage): void
  disabled?: boolean
  extended?: boolean
}

const MessageConfiguration: React.FC<MessageConfigurationProps> = ({
  data,
  messagesData,
  handleConfirm,
  disabled,
  extended
}) => {
  const [messageDialog, setMessageDialog] = useState<boolean>(false)
  const [currentMessage, setCurrentMessage] = useState<ConfigurationMessage>({
    _id: '',
    createdAt: {
      utc: ''
    },
    updatedAt: {
      utc: ''
    },
    key: ConfigurationKeyEnum.Message,
    content: '',
    title: ''
  })

  useEffect(() => {
    if (messageDialog) {
      if (data && data.configurationMap.messageCurrent) {
        setCurrentMessage(data.configurationMap.messageCurrent)
      }
    }
  }, [data, messageDialog])

  const handleOpen = () => {
    setMessageDialog(true)
  }

  const handleClose = () => {
    setMessageDialog(false)
  }

  return (
    <>
      <Fab
        color="primary"
        aria-label="message"
        onClick={handleOpen}
        variant={extended ? 'extended' : 'round'}
        disabled={disabled}
      >
        <MessageIcon />
        {extended && 'Mensagem'}
      </Fab>
      {/* Show dialog */}
      <Dialog
        open={messageDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Mostrar uma mensagem ?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao selecionar uma mensagem, você ira exibi-la para todos os
            participantes ativos no momento, impedindo-os de realizar outras
            ações dentro do sistema.
          </DialogContentText>
          <Autocomplete
            id="combo-box-demo"
            options={messagesData.messages.edges}
            getOptionLabel={(option: ConfigurationMessage) =>
              option.title ? option.title : ''
            }
            onChange={(e, newValue) => {
              if (newValue) {
                setCurrentMessage(newValue)
              } else {
                setCurrentMessage({
                  _id: '',
                  createdAt: {
                    utc: ''
                  },
                  updatedAt: {
                    utc: ''
                  },
                  key: ConfigurationKeyEnum.Message,
                  content: '',
                  title: ''
                })
              }
            }}
            getOptionSelected={(option, value) =>
              option.title === value.title && option.content === value.content
            }
            defaultValue={currentMessage}
            renderInput={params => (
              <TextField
                {...params}
                label="Mensagem a exibir"
                variant="outlined"
                name="current_message"
                required
              />
            )}
          />
          {currentMessage.content && (
            <>
              <h1>{currentMessage.title}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: currentMessage.content }}
              ></div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              if (
                currentMessage.content === '' &&
                currentMessage.title === ''
              ) {
                return toast.error('Escolha uma mensagem válida.')
              }
              handleClose()
              handleConfirm(currentMessage)
            }}
            color="primary"
            autoFocus
          >
            Mostrar mensagem!
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MessageConfiguration
