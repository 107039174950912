import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Button, Grid } from '@material-ui/core'
import { CategoryData } from './category-types'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    btnBack: {
      padding: '16px',
      width: '40%',
      margin: '0 10% 0 0'
    },
    btnSave: {
      padding: '16px',
      width: '40%',
      margin: '0 0 0 10%'
    }
  })
)

interface FormProps {
  onSubmit(event: React.FormEvent<HTMLFormElement>): void
  onChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void
  data?: CategoryData
  loading: boolean
}

const CategoryForm: React.FC<FormProps> = ({
  onSubmit,
  onChange,
  data,
  loading
}) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <form
      className={classes.root}
      autoComplete="off"
      onSubmit={event => onSubmit(event)}
    >
      <TextField
        label="Nome da categoria"
        variant="outlined"
        onChange={e => onChange(e)}
        name="name"
        defaultValue={data ? data.name : ''}
        required
      />
      <TextField
        label="Numero da categoria"
        type="number"
        variant="outlined"
        onChange={e => onChange(e)}
        name="number"
        defaultValue={data ? data.number : ''}
        required
      />
      <TextField
        label="Ganhadores"
        type="number"
        variant="outlined"
        onChange={e => onChange(e)}
        name="quantityWin"
        defaultValue={data ? data.quantityWin : ''}
        required
      />
      <Grid sm={12} justify="space-around" alignContent="space-around">
        <Button
          variant="outlined"
          color="primary"
          className={classes.btnBack}
          onClick={() => history.goBack()}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnSave}
          type="submit"
          disabled={loading}
        >
          Salvar
        </Button>
      </Grid>
    </form>
  )
}

export default CategoryForm
