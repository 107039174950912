import React, { useEffect } from 'react'
import { Button, Container, Grid } from '@material-ui/core'
import { useKeycloak } from '@react-keycloak/web'

const Home: React.FC = () => {
  const [keycloak] = useKeycloak()

  useEffect(() => {
    keycloak.login()
  }, [keycloak])

  return (
    <Container maxWidth="lg">
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        style={{ height: '100vh' }}
      >
        <h1>Innovare</h1>
        <Button
          color="primary"
          variant="contained"
          onClick={() => keycloak.login()}
        >
          Acessar
        </Button>
      </Grid>
    </Container>
  )
}

export default Home
