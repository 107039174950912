import { useMutation } from '@apollo/client'
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  makeStyles,
  TextField,
  Theme
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import React from 'react'
import { Category, CategoryStatusEnum } from '../Categories/category-types'
import { CLEAR_VOTE } from '../GraphQl/vote'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    }
  })
)

interface ClearVoteProps {
  loading: boolean
  category: Category
}

const ClearVote: React.FC<ClearVoteProps> = ({ loading, category }) => {
  const [clearAllVotes] = useMutation(CLEAR_VOTE)
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState<string>('')
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setValue('')
    setOpen(false)
  }

  const handleConfirm = () => {
    clearAllVotes({
      variables: {
        category: category._id,
        action: value
      }
    })
    handleClose()
  }

  return (
    <>
      <Fab
        color="primary"
        size="large"
        aria-label="votacao"
        variant="extended"
        className={classes.extendedIcon}
        onClick={handleClickOpen}
        disabled={loading || category.status === CategoryStatusEnum.VOTING}
      >
        <DeleteIcon />
        Deletar Votos
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Limpar votação ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirmar apagará todos os votos de uma categoria, para confirmar
            digite: CLEAR e confirme
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Palavra-chave"
            type="value"
            value={value}
            fullWidth
            onChange={e => setValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ClearVote
