import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { MessageData } from '../../components/messages/message-types'
import { ADD_MESSAGE } from '../../components/GraphQl/messages'
import MessageForm from '../../components/messages/form-messages'

const MessageNew: React.FC = () => {
  const [addMessage, { loading: mutationLoading }] = useMutation(ADD_MESSAGE)

  const history = useHistory()

  const [message, setMessage] = useState<MessageData>({
    content: '',
    title: ''
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    addMessage({ variables: { input: message } }).then(() => {
      history.push('/admin/mensagens')
    })
  }

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    setMessage(message => ({ ...message, [name]: value }))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeMessage = (event: any) => {
    setMessage(message => ({ ...message, content: event.target.getContent() }))
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Mensagens
      </Typography>
      <Grid item xs={12}>
        <MessageForm
          onSubmit={event => handleSubmit(event)}
          onChange={handleChange}
          onChangeMessage={event => handleChangeMessage(event)}
          loading={mutationLoading}
          message={message}
        />
      </Grid>
    </>
  )
}

export default MessageNew
