import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography
} from '@material-ui/core'
import MuiDialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { ConfigurationMap } from './configuration-types'
import { CommunicationEnum } from '../../schema'

interface DataMap {
  configurationMap: ConfigurationMap
}

interface MessageConfigurationProps {
  data: DataMap | undefined
  handleConfirm(option: CommunicationEnum, value: string): void
  loading: boolean
}
const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}))(MuiDialogContent)

const CommunicationConfiguration: React.FC<MessageConfigurationProps> = ({
  data,
  handleConfirm,
  loading
}) => {
  const [messageDialog, setMessageDialog] = useState<boolean>(false)
  const [openTooltip, setOpenToolTip] = useState<boolean>(false)
  const [currentConfig, setCurrentConfig] = useState<CommunicationEnum>(
    CommunicationEnum.IFRAME
  )
  const [value, setValue] = useState<string>('')
  const [zoomConfig, setZoomConfig] = useState<{ num: string; pwd: string }>({
    num: '',
    pwd: ''
  })

  useEffect(() => {
    if (messageDialog) {
      if (
        data &&
        data.configurationMap.streaming &&
        data.configurationMap.streaming?.enable === false
      ) {
        return setCurrentConfig(CommunicationEnum.DISABLED)
      } else if (
        data &&
        data.configurationMap.streaming &&
        data.configurationMap.streaming?.iframe
      ) {
        return setCurrentConfig(CommunicationEnum.IFRAME)
      } else if (
        data &&
        data.configurationMap.streaming &&
        data.configurationMap.streaming?.embed
      ) {
        return setCurrentConfig(CommunicationEnum.EMBED)
      }
    }
  }, [data, messageDialog])

  useEffect(() => {
    setValue(getIframe())
  }, [zoomConfig])

  const handleOpen = () => {
    setMessageDialog(true)
  }

  const handleClose = () => {
    setMessageDialog(false)
  }

  const handleTooltipClose = () => {
    setOpenToolTip(false)
  }

  const getIframe = () => {
    const zoomURL = `https://zoom.votepraticasinnovare.com.br/#/meeting?pwd=${zoomConfig.pwd}&mn=${zoomConfig.num}`
    return `<iframe style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;"
                    src="${zoomURL}" frameborder="0"
                    allowfullscreen
                    allow="camera;microphone"></iframe>`
  }

  return (
    <>
      {/* Messages section */}
      <Grid item>
        <Typography variant="h5" color="textPrimary" component="h5">
          Comunicação e Transmissão
        </Typography>
        <br />
        <Grid item style={{ margin: '0 auto' }}>
          <Fab color="primary" aria-label="message" onClick={handleOpen}>
            <i className="fas fa-video" style={{ fontSize: '1.5rem' }} />
          </Fab>
        </Grid>
        {/* Show dialog */}
        <Dialog
          open={messageDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <form
            onSubmit={e => {
              e.preventDefault()
              handleConfirm(currentConfig, value)
              handleClose()
            }}
          >
            <DialogTitle id="alert-dialog-title">
              {'Alterar origem da comunicação ?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Ao selecionar uma origem de comunicação, você ira exibi-la para
                todos os participantes ativos no momento, possibilitando a
                conexão entre os participantes e espectadores.
              </DialogContentText>
              <InputLabel id="font-align-select">
                Escolha entre Comunicação, transmissão ou desative a comunicação
              </InputLabel>
              <br />
              <Select
                fullWidth
                id="font-align-select"
                name="type"
                variant="outlined"
                defaultValue={currentConfig}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setCurrentConfig(e.target.value as CommunicationEnum)
                }
              >
                <MenuItem value={CommunicationEnum.IFRAME}>
                  Comunicação
                </MenuItem>
                <MenuItem value={CommunicationEnum.EMBED}>Transmissão</MenuItem>
                <MenuItem value={CommunicationEnum.ZOOM}>ZOOM</MenuItem>
                <MenuItem value={CommunicationEnum.DISABLED}>
                  DESATIVAR
                </MenuItem>
              </Select>
              {currentConfig === CommunicationEnum.IFRAME && (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true
                      }}
                      onClose={handleTooltipClose}
                      placement="top-start"
                      open={openTooltip}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Somente HTTPS"
                      arrow
                    >
                      <TextField
                        fullWidth
                        label="Iframe de comunicação"
                        defaultValue={data?.configurationMap.streaming?.iframe}
                        onChange={e => setValue(e.target.value)}
                        onFocus={() => setOpenToolTip(true)}
                        name="value"
                        variant="outlined"
                        type="url"
                        inputProps={{ pattern: 'https://.*' }}
                      />
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              )}
              {currentConfig === CommunicationEnum.EMBED && (
                <TextField
                  fullWidth
                  label="Embed de comunicação"
                  defaultValue={data?.configurationMap.streaming?.embed}
                  onChange={e => setValue(e.target.value)}
                  name="value"
                  variant="outlined"
                  multiline
                  rows={10}
                />
              )}

              {currentConfig === CommunicationEnum.ZOOM && (
                <>
                  <TextField
                    fullWidth
                    label="Número da sala"
                    onChange={e =>
                      setZoomConfig({ ...zoomConfig, num: e.target.value })
                    }
                    name="roomNumber"
                    variant="outlined"
                  />

                  <TextField
                    fullWidth
                    label="Senha de acesso à sala"
                    onChange={e =>
                      setZoomConfig({ ...zoomConfig, pwd: e.target.value })
                    }
                    name="roomPwd"
                    variant="outlined"
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Button
                color="primary"
                type="submit"
                autoFocus
                disabled={loading}
              >
                Atualizar
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Grid>
    </>
  )
}

export default CommunicationConfiguration
