import React from 'react'
import { ReactComponent as BlockedImg } from '../assets/broken.svg'
import './blocked.css'

const BlockedPage: React.FC = () => {
  return (
    <div id="container">
      <BlockedImg style={{ width: '70%' }} />
      <h1>Acesso bloqueado</h1>
      <p>
        Você não possui as permissões necessarias para acessar essa parte do
        sistema.
      </p>
      <p>AGUARDE, VOCÊ SERÁ REDIRECIONADO PARA O LOGIN AUTOMATICAMENTE.</p>
      <a className="btn-back" href="/">
        Tentar novamente
      </a>
    </div>
  )
}

export default BlockedPage
