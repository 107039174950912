import React, { ChangeEvent, useEffect, useState } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { MenuItem, Select } from '@material-ui/core'
import { Data } from '../Categories/category-types'
import CategoryActions from './category-actions'
import {
  ConfigurationMap,
  ConfigurationScreenOptionsInput,
  ConfigurationScreenTypeEnum,
  Participant
} from '../configuration/configuration-types'
import ParticipantList from './participant-list'
import {
  RESULT_PARTICIPANT,
  SUBSCRIPTION_NEW_VOTE,
  SUBSCRIPTION_RESULT_PARTICIPANT
} from '../GraphQl/vote'
import VotingCounter from './voting-counter'

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
})

interface VoteCategoryNavigationProps {
  data: Data
  configurationMap: ConfigurationMap
  openVoting(category: string): void
  closeVoting(): void
  loading: boolean
  setScreen(
    screen: ConfigurationScreenTypeEnum,
    options?: ConfigurationScreenOptionsInput
  ): void
  startAutoResult(): void
  stopAutoResult(): void
}

export const VoteCategoryNavigation: React.FC<VoteCategoryNavigationProps> = ({
  data,
  configurationMap,
  openVoting,
  closeVoting,
  setScreen,
  loading,
  startAutoResult,
  stopAutoResult
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const defaultVariables = {
    category: configurationMap.screen?.category?._id,
    params: {
      sort: {
        name: 1
      },
      filter: {
        status: 'APPROVED'
      }
    },
    fetchPolicy: 'network-only'
  }
  const [participants, setParticipants] = useState<Participant[]>([])

  const { loading: loadingParticipants, refetch } = useQuery(
    RESULT_PARTICIPANT,
    {
      variables: defaultVariables,
      onCompleted: ({ participants }) => {
        if (participants?.edges) {
          setParticipants([...participants.edges])
        }
      }
    }
  )

  useSubscription(SUBSCRIPTION_RESULT_PARTICIPANT, {
    variables: defaultVariables,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData?.data?.participantOnChanges?.data) {
        updateParticipant(subscriptionData.data.participantOnChanges.data)
      }
    }
  })

  useSubscription(SUBSCRIPTION_NEW_VOTE, {
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData?.data?.onNewVote) {
        const vote = subscriptionData?.data?.onNewVote
        console.log('NEW+VOTE', vote)
        updateParticipant({
          _id: vote.participantKeypad,
          keypad: vote.participantKeypad,
          votedPractices: vote.votedPractices
        })
      }
    }
  })

  const updateParticipant = (
    participantChanged: Partial<Participant>
  ): void => {
    const newListParticipants = [...participants]
    const index = participants.findIndex(
      p => p.keypad === participantChanged.keypad
    )

    if (index !== -1) {
      newListParticipants[index] = {
        ...participants[index],
        ...participantChanged
      }
    } else {
      newListParticipants.push(participantChanged as any)
    }

    setParticipants(newListParticipants)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSelect = (
    e: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setActiveStep(e.target.value as number)
  }

  useEffect(() => {
    if (configurationMap.screen?.category !== null) {
      setActiveStep(
        data.categories.edges.findIndex(
          category => category._id === configurationMap.screen?.category?._id
        )
      )
    }
  }, [data, configurationMap])

  return (
    <div style={{ textAlign: 'center' }}>
      <Select onChange={handleSelect} value={activeStep}>
        {data.categories.edges.map((cate, index) => (
          <MenuItem key={cate._id} value={index}>
            {cate.number} - {cate.name} ({cate.quantityWin})
          </MenuItem>
        ))}
      </Select>
      <MobileStepper
        variant="dots"
        steps={data.categories.edges.length}
        position="static"
        activeStep={activeStep}
        className={classes.root}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === data.categories.edges.length - 1}
          >
            Avançar
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Voltar
          </Button>
        }
      />
      <CategoryActions
        category={data.categories.edges[activeStep]}
        configurationMap={configurationMap}
        openVoting={openVoting}
        closeVoting={closeVoting}
        setScreen={setScreen}
        startAutoResult={startAutoResult}
        stopAutoResult={stopAutoResult}
        loading={loading}
      />
      <br />
      {configurationMap.screen?.category && (
        <VotingCounter configurationMap={configurationMap} />
      )}
      <br />
      <ParticipantList
        loading={loading || loadingParticipants}
        participants={participants}
        refetch={() => refetch(defaultVariables)}
      />
    </div>
  )
}
export default VoteCategoryNavigation
