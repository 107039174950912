import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { GET_MESSAGE, UPDATE_MESSAGE } from '../../components/GraphQl/messages'
import { MessageData } from '../../components/messages/message-types'
import MessageForm from '../../components/messages/form-messages'

interface MatchParams {
  id: string
}

type MessageEditProps = RouteComponentProps<MatchParams>

const MessageEdit: React.FC<MessageEditProps> = ({ match }) => {
  const [updateMessage, { loading: mutationLoading }] = useMutation(
    UPDATE_MESSAGE
  )
  const { id } = match.params

  const { data, loading, error } = useQuery(GET_MESSAGE, {
    variables: {
      id
    }
  })

  const history = useHistory()

  const [message, setMessage] = useState<MessageData>({
    title: '',
    content: ''
  })

  useEffect(() => {
    if (data && data.message) {
      setMessage(data.message)
    }
  }, [data])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    updateMessage({
      variables: {
        id,
        inputUpdate: {
          title: message.title,
          content: message.content
        }
      }
    }).then(() => {
      history.push('/admin/mensagens')
    })
  }

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    setMessage(message => ({ ...message, [name]: value }))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeMessage = (event: any) => {
    setMessage(message => ({ ...message, content: event.target.getContent() }))
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Mensagem: {(data && data.message && data.message.title) || ''}
      </Typography>
      <Grid item xs={12}>
        {error && <h1>{error.message}</h1>}
        {loading ? (
          <h1>Carregando...</h1>
        ) : (
          <MessageForm
            loading={mutationLoading}
            onSubmit={event => handleSubmit(event)}
            onChange={handleChange}
            onChangeMessage={event => handleChangeMessage(event)}
            message={data.message}
          />
        )}
      </Grid>
    </>
  )
}

export default MessageEdit
