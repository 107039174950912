import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import {
  GET_PARTICPANT,
  UPDATE_PARTICIPANT
} from '../../components/GraphQl/participants'
import UserForm from '../../components/Users/form'
import { toast } from 'react-toastify'

interface MatchParams {
  id: string
}

type ParticipantEditProps = RouteComponentProps<MatchParams>

interface ParticipantEdit {
  name: string
  role: string
  login: string
  keypad: string
  password: string
}

const ParticipantEdit: React.FC<ParticipantEditProps> = ({ match }) => {
  const [updateParticipant, { loading: loadginUpdate }] = useMutation(
    UPDATE_PARTICIPANT
  )
  const { id } = match.params

  const { data, loading, error } = useQuery(GET_PARTICPANT, {
    variables: {
      id
    }
  })

  const history = useHistory()

  const [participant, setParticipant] = useState<ParticipantEdit>({
    name: '',
    role: 'VOTER',
    login: '',
    keypad: '',
    password: ''
  })

  useEffect(() => {
    if (data && data.participant) {
      setParticipant(data.participant)
    }
  }, [data])

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (participant.role === '') {
      return toast.error('Escolha uma função para o participante')
    }
    if (participant.password === '') {
      updateParticipant({
        variables: {
          id,
          inputUpdate: {
            name: participant.name,
            role: participant.role,
            login: participant.login,
            keypad: participant.keypad
          }
        }
      }).then(() => {
        history.push('/admin/usuarios')
      })
    } else {
      updateParticipant({
        variables: {
          id,
          inputUpdate: {
            name: participant.name,
            role: participant.role,
            login: participant.login,
            keypad: participant.keypad,
            password: participant.password
          }
        }
      }).then(() => {
        history.push('/admin/usuarios')
      })
    }
  }

  const handleChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.currentTarget
    setParticipant(participant => ({ ...participant, [name]: value }))
  }

  return (
    <>
      <Typography variant="h3" color="textPrimary" component="h1">
        Prática {(data && data.practice && data.practice.name) || ''}
      </Typography>
      <Grid item xs={12}>
        {error && <h1>{error.message}</h1>}
        {loading ? (
          <h1>Carregando...</h1>
        ) : (
          <UserForm
            edit
            loading={loadginUpdate}
            onSubmit={event => handleSubmit(event)}
            onChange={handleChange}
            data={data.participant}
          />
        )}
      </Grid>
    </>
  )
}

export default ParticipantEdit
