import { gql } from '@apollo/client'

const FRAGMENT_STYLE = gql`
  fragment StyleFrag on ConfigurationAppearanceStyle {
    colors {
      backgroundColor
      primary
      secondary
      textColor
    }
    font {
      fontAlign
      fontFamily
      fontSize
    }
  }
`

const FRAGMENT_CONFIG = gql`
  fragment ConfigFrag on ConfigurationMap {
    screen {
      screenType
      category {
        _id
        number
        name
        status
        practices {
          _id
          name
          number
        }
        awards {
          _id
          name
        }
      }
      results {
        name
        number
      }
    }
    messageCurrent {
      title
      content
    }
    streaming {
      enable
      embed
      iframe
    }
    signUp {
      authType
    }
    appearance {
      assets {
        backgroundImage
        logo
      }

      globalStyle {
        ...StyleFrag
      }

      practiceStyle {
        ...StyleFrag
      }

      categoryStyle {
        ...StyleFrag
      }
    }
  }
`

export const GET_CONFIGURATION = gql`
  query GetConfMap {
    configurationMap {
      ...ConfigFrag
    }
  }

  ${FRAGMENT_STYLE}
  ${FRAGMENT_CONFIG}
`
export const SUBSCRIPTION_CONFIG = gql`
  subscription ConfMapOnChage {
    configurationMapOnChanges {
      key
      data {
        ...ConfigFrag
      }
    }
  }

  ${FRAGMENT_STYLE}
  ${FRAGMENT_CONFIG}
`
export const CONFIGURATION_MESSAGE = gql`
  mutation ConfigurationMessage(
    $inputConfigMessage: ConfigurationMessageInput!
  ) {
    setConfigMessage(input: $inputConfigMessage) {
      message
      data {
        __typename
        ... on ConfigurationMessage {
          _id
          key
          title
          content
          createdAt {
            utc
          }
        }
      }
    }
  }
`
export const CONFIGURATION_APPEARANCE = gql`
  mutation SetConfiguration(
    $assets: ConfigurationAppearanceAssetsInput
    $global: ConfigurationAppearanceStyleInput
    $practices: ConfigurationAppearanceStyleInput
    $categories: ConfigurationAppearanceStyleInput
  ) {
    setConfigAppearance(
      assets: $assets
      categories: $categories
      practices: $practices
      global: $global
    ) {
      message
      data {
        __typename
        ... on ConfigurationAppearance {
          assets {
            backgroundImage
            logo
          }

          globalStyle {
            ...StyleFrag
          }

          practiceStyle {
            ...StyleFrag
          }

          categoryStyle {
            ...StyleFrag
          }
        }
      }
    }
  }
  ${FRAGMENT_STYLE}
`
export const CONFIGURATION_COMMUNICATION = gql`
  mutation SetConfStreaming($input: ConfigurationStreamingInput!) {
    setConfigStreaming(input: $input) {
      message
      data {
        __typename
        ... on ConfigurationStreaming {
          _id
          key
          enable
          iframe
          embed
          createdAt {
            utc
          }
        }
      }
    }
  }
`
export const CONFIGURATION_REGISTER = gql`
  mutation SetConfSignUp($authType: ConfigurationSignUpAuthTypeEnum!) {
    setConfigSignUp(authType: $authType) {
      message
      data {
        __typename
        ... on ConfigurationSignUp {
          _id
          key
          authType
          createdAt {
            utc
          }
        }
      }
    }
  }
`
export const CONFIGURATION_VOTE = gql`
  mutation SetConfVote($duplicateVote: Boolean!) {
    setConfigVote(duplicateVote: $duplicateVote) {
      message
      data {
        __typename
        ... on ConfigurationVote {
          _id
          key
          duplicateVote
          createdAt {
            utc
          }
        }
      }
    }
  }
`
